

import { useContext } from 'react';
import { imageUrl } from '../../../data/utils/service-helpers';
import MessageContext from '../../../utils/messages/message-context';
import { PEOPLE_DIRECTORY } from './constants';
import Person from './data/Person';

export default function PersonView(person: Person) {
    const messages = useContext(MessageContext);
    return (
        <div className="person-info">
            { (person.image || person.imageName) &&
                <img src={person.image || imageUrl(person.imageName, PEOPLE_DIRECTORY)} alt={`${messages.IMAGE} ${person.name}`}/>
            }
           <div className="person-title-column">
                <h2>{ person.name }</h2>
                <h3>{ person.jobTitle }</h3>
           </div>
            <p>
                { person.description }
            </p>
        </div>
    );
}
