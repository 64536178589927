import CaseItem from "../CaseItem";
import { APP_MESSAGES } from "../../../../utils/messages/messages";
import Upload from "../../../Helpers/Form/Upload";
import { caseRules } from "../../../../data/model/rules";
import { IMAGE_FIELDS } from "../constants";

const createInputs = (messages: APP_MESSAGES) => ({
    title: {
      type: 'input',
      label: messages.TITLE
    },
    description: {
      type: 'textarea',
      label: messages.DESCRIPTION,
      props: { rows: 8 }
    },
    image: {
      type: Upload,
      props: { text: `${messages.BEFORE} ${messages.IMAGE}` }
    },
    imageAfter: {
      type: Upload,
      props: { text: `${messages.AFTER} ${messages.IMAGE}` }
    }
});

const formProps = {
    createInputs,
    rules: caseRules,
    imageFields: IMAGE_FIELDS,
    ItemElement: CaseItem
};

export default formProps;