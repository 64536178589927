import { createContext } from 'react';
import CaseService from '../Components/Pages/Cases/data/case.service';
import LoginService from '../Components/Pages/User/login.service';
import NotifcationService from '../Components/Helpers/Notification/notification-service';
import PopupService from '../Components/Helpers/Popup/popup.service';
import InfoService from './page-info/page-info.service';
import PeopleService from '../Components/Pages/About/data/PeopleService';
import CaseInfoService from '../Components/Pages/CaseInfo/data/case-info.service';

export interface Services {
    caseService: CaseService,
    loginService: LoginService,
    notificationService: NotifcationService,
    popupService: PopupService,
    infoService: InfoService
    peopleService: PeopleService
    caseInfoService: CaseInfoService
}

const ServiceContext = createContext({} as Services);

export default ServiceContext;