import { ElementType, SyntheticEvent, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import EditButton from '../Button/EditButton';
import IconButton from '../Button/IconButton';
import { AddIcon, DeleteIcon, EditIcon } from '../Button/icons';
import NotificationType from '../Notification/NotificationType';
import ServiceContext from '../../../data/ServiceContext';
import AdminContent from '../../Pages/User/AdminContent';
import CrudService from '../../../data/utils/crud.service';
import { ListItem } from './ListItem';
import './listview.css';
import MessageContext from '../../../utils/messages/message-context';
import useSaveErrorHandler from '../../Hooks/useSaveErrorHandler';

export interface ListViewProps {
    service: CrudService<ListItem>;
    data: ListItem[];
    listClass?: string;
    wrapperClass?: string;
    ItemElement: string | ElementType;
    details?: boolean;
    itemClass?: Function;
}

const ENTER = 'Enter';

function itemClickHandler(item: any, fn: Function) {
    return (e: SyntheticEvent) => fn(item, e);
}

export default function ListView({ service, data, listClass, wrapperClass,
    ItemElement, details, itemClass }: ListViewProps) {
    const messages = useContext(MessageContext);
    const services = useContext(ServiceContext);
    const navigate = useNavigate();
    const location = useLocation();

    const errorHandler = useSaveErrorHandler();

    function onItemClick(item: ListItem): void {
        if (details) {
            navigate(`${item.id}`);
        }
    }

    function onkeydown(e: KeyboardEvent, item: ListItem) {
        if(details && e.key === ENTER) {
            navigate(`${item.id}`);
        }
    }

    function onRemoveClick(item: ListItem, e: any) {
        e.stopPropagation();
        services.popupService.showConfirm(messages.CONFIRM_DELETE, messages.YES, messages.NO).then(() => {
            service.remove(item).then((result) => {
                services.notificationService.show(NotificationType.Success, messages.SAVED_CHANGES);
                return result;
            }).catch(errorHandler);
        });
    }

    function onEditClick(item: ListItem, e: any) {
        e.stopPropagation();
        navigate(`${location.pathname}/edit/${item.id}`);
    }

    return (
        <div className={wrapperClass || 'listview-wrapper'}>
            <AdminContent>
                <EditButton path={`${location.pathname}/add`} message={messages.ADD} icon={AddIcon} />
            </AdminContent>
            <ul className={listClass || 'listview'}>
                {data.map((item: ListItem, index: number) => (
                    <li key={item.id} onClick={itemClickHandler(item, onItemClick)} tabIndex={0}
                    onKeyDown={(e: any) => onkeydown(e, item)}
                    {...(details ? { 'data-href': `${location.pathname}/${item.id}`, role: "link" } : {})}
                    className={`list-item active-item${itemClass ? ' ' + itemClass(item, index) : ''}`}>
                        <AdminContent>
                            <div className="actions-right">
                                <IconButton IconElement={EditIcon}
                                    onClick={itemClickHandler(item, onEditClick)} props={{ title: messages.EDIT }} />
                                <IconButton IconElement={DeleteIcon}
                                    onClick={itemClickHandler(item, onRemoveClick)} props={{ title: messages.DELETE }} />
                            </div>
                        </AdminContent>
                        <ItemElement {...item}/>
                    </li>
                ))}
            </ul>
        </div>
    );
}
