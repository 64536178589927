import { useEffect, useRef } from "react";

export default function useResizeObserver(callback: ResizeObserverCallback): React.RefObject<HTMLElement> {
    const ref = useRef<HTMLElement>(null);

    useEffect(() => {
      if (!ref.current) {
        return;
      }

      const resizeObserver = new ResizeObserver(callback);
      resizeObserver.observe(ref.current);
      return () => {
        resizeObserver.disconnect();
      };
    }, [ref, callback]);

    return ref;
  }