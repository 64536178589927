import { useMemo } from 'react';
import Home from './Components/Pages/Home/Home';
import { Routes, Route } from 'react-router-dom';
import Layout from './Components/Pages/Layout/Layout';
import AboutUs from './Components/Pages/About/AboutUs';
import CaseList from './Components/Pages/Cases/CaseList';
import Contacts from './Components/Pages/Contacts/Contacts';
import CaseDetails from './Components/Pages/Cases/CaseDetails';
import EditForm from './Components/Pages/Cases/forms/EditForm';
import AddForm from './Components/Pages/Cases/forms/AddForm';
import Login from './Components/Pages/User/Login';
import NoMatch from './Components/Helpers/Page/NoMatch';

import { Provider } from 'react-redux';
import { RootStore } from './data/store/store';
import ServiceContext, { Services } from './data/ServiceContext';
import CaseService from './Components/Pages/Cases/data/case.service';
import LoginService from './Components/Pages/User/login.service';
import NotificationService from './Components/Helpers/Notification/notification-service';
import PopupService from './Components/Helpers/Popup/popup.service';
import InfoService from './data/page-info/page-info.service';
import HomeForm from './Components/Pages/Home/HomeForm';
import AddAboutForm from './Components/Pages/About/forms/AddForm';
import EditAboutForm from './Components/Pages/About/forms/EditForm';
import ContactsForm from './Components/Pages/Contacts/ContactsForm';
import PeopleService from './Components/Pages/About/data/PeopleService';
import Page from './Components/Helpers/Page/Page';
import MessageContext from './utils/messages/message-context';
import { MESSAGES } from './utils/messages/messages';
import CaseInfoService from './Components/Pages/CaseInfo/data/case-info.service';
import CaseInfoList from './Components/Pages/CaseInfo/CaseInfoList';
import CaseInfoAddForm from './Components/Pages/CaseInfo/forms/AddForm';
import CaseInfoEditForm  from './Components/Pages/CaseInfo/forms/EditForm';
import CaseInfoDetails from './Components/Pages/CaseInfo/CaseInfoDetails';
import LocaleContext from './utils/Intl/LocaleContext';
import pageTitle from './utils/page-title';
import Account from './Components/Pages/User/Account';

const LOCALE = 'bg';

function App({ store }: { store: RootStore}) {
  const caseDetailTitle = pageTitle(state => state.cases, `${MESSAGES.DETAILS} ${MESSAGES.CASE}`);
  const caseInfoDetailTitle = pageTitle(state => state.caseInfo, `${MESSAGES.DETAILS} ${MESSAGES.CASE_INFO}`);

  const services: Services = useMemo(() => ({
    caseService: new CaseService(store),
    loginService: new LoginService(store),
    notificationService: new NotificationService(store),
    popupService: new PopupService(store),
    infoService: new InfoService(store),
    peopleService: new PeopleService(store),
    caseInfoService: new CaseInfoService(store)
  }), [store]);

  const messages = MESSAGES;

  return (
    <Provider store={store}>
       <ServiceContext.Provider value={services}>
        <MessageContext.Provider value={messages}>
          <LocaleContext.Provider value={LOCALE}>
          <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Page component={<Home />} title={messages.HOME} />} />
                <Route path="home/edit" element={<Page requireLogin={true} component={<HomeForm />}
                  title={`${messages.EDIT} ${ messages.HOME}`} />} />

                <Route path="cases">
                  <Route index element={<Page title={messages.CASES} component={<CaseList />} />} />
                  <Route path=":id" element={<Page component={<CaseDetails />}
                    title={caseDetailTitle} />} />
                  <Route path="edit/:id" element={<Page requireLogin={true} component={<EditForm />}
                    title={`${messages.EDIT} ${messages.CASE}`} />} />
                  <Route path="add"  element={<Page requireLogin={true} component={<AddForm />}
                    title={`${messages.ADD} ${messages.CASE}`} />} />
                </Route>

                <Route path="case-info/">
                    <Route index element={<Page title={messages.CASE_INFO} component={<CaseInfoList />} />} />
                    <Route path=":id" element={<Page component={<CaseInfoDetails />}
                  title={caseInfoDetailTitle} />} />
                    <Route path="edit/:id" element={<Page requireLogin={true} component={<CaseInfoEditForm />}
                  title={`${messages.EDIT} ${messages.CASE_INFO}`} />} />
                    <Route path="add"  element={<Page requireLogin={true} component={<CaseInfoAddForm />}
                      title={`${messages.ADD} ${messages.CASE_INFO}`} />} />
                </Route>

                <Route path="about/">
                  <Route index element={<Page title={messages.ABOUT} component={<AboutUs />} />} />
                  <Route path="edit/:id" element={<Page requireLogin={true} component={<EditAboutForm />}
                    title={`${messages.EDIT} ${messages.PERSON}`} />} />
                  <Route path="add" element={<Page requireLogin={true} component={<AddAboutForm />}
                    title={`${messages.ADD} ${messages.PERSON}`} />} />
                </Route>

                <Route path="contacts/">
                  <Route index element={<Page title={messages.CONTACT} component={<Contacts />} />} />
                  <Route path="edit" element={<Page requireLogin={true} component={<ContactsForm />}
                    title={`${messages.EDIT} ${messages.CONTACT}`} />} />
                </Route>

                <Route path="login" element={<Page title={messages.LOGIN} component={<Login />} />} />
                <Route path="account" element={<Page requireLogin={true} title={messages.ACCOUNT} component={<Account />} />} />
                <Route path="*" element={<Page title={messages.PAGE_NOT_FOUND} component={<NoMatch />} />} />

              </Route>
          </Routes>
          </LocaleContext.Provider>
        </MessageContext.Provider>
      </ServiceContext.Provider>
    </Provider>
  );
}

export default App;
