
import IconButton from '../../Helpers/Button/IconButton';
import { DeleteIcon } from '../../Helpers/Button/icons';
import { extend } from '../../../utils/helpers';
import DayRange from './DayRange';
import TimeRangeList from './TimeRangeList';

export default function WorkHoursRange({ range, onChange, onRemove }:
    { range: { startDay: number, endDay: number, ranges: string[][] }, onRemove: Function, onChange: Function}) {

    function onDayRangeChange(dayRange: any) {
        onChange(extend(range, dayRange));
    }

    function onRangesChange(timeRanges: string[]) {
        onChange(extend(range, { ranges: timeRanges }));
    }

    return (
        <>
            <div className="day-range">
                <DayRange range={range} onChange={onDayRangeChange}/>
                <IconButton IconElement={DeleteIcon} onClick={onRemove}>
                </IconButton>
            </div>
            <TimeRangeList ranges={range.ranges} onChange={onRangesChange} />
        </>
    )
}
