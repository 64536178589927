import { RootStore, setLoading } from "../store/store";
import { createFormData, FORM_DATA_HEADERS } from "./service-helpers";
import axios from 'axios';


export interface CrudActions {
    setItems: Function;
    updateItem: Function;
    createItem: Function;
    removeItem: Function;
}

export interface Parsers {
    [key: string]: Function;
}

export default abstract class CrudService<T> {
    private loading: boolean = false;
    private store: RootStore;
    private url: string;
    private actions: CrudActions;
    private parsers?: Parsers;

    constructor(store: RootStore, url: string, actions: CrudActions, parsers?: Parsers) {
        this.store = store;
        this.url = url;
        this.actions = actions;
        this.parsers = parsers;
    }

    load = () => {
        if (this.loading) {
            return;
        }

        this.loading = true;
        return this.makeRequest('GET').then(result => {
            this.store.dispatch(this.actions.setItems(this.parseItems(result.data)));
            return result;
        }).finally(() => {
            this.loading = false;
        });
    }

    create = (item: T): Promise<any> => {
        return this.makeRequest('POST', createFormData(item), FORM_DATA_HEADERS).then(response => {
            if (!response.data?.errors) {
                this.store.dispatch(this.actions.createItem(this.parseItem(response.data)));
            }
            return response;
        });
    }

    update = (item: T): Promise<any> => {
        return this.makeRequest('PUT', createFormData(item), FORM_DATA_HEADERS).then(response => {
            if (!response.data?.errors) {
                this.store.dispatch(this.actions.updateItem(this.parseItem(response.data)));
            }
            return response;
        });
    }

    remove = (item: T): Promise<any> => {
        return this.makeRequest('DELETE', item).then((result) => {
            this.store.dispatch(this.actions.removeItem(item));

            return result;
        });
    }

    private parseItems(items: any[]) {
        if(this.parsers && items) {
            items.forEach(item => this.parseItem(item));
        }

        return items;
    }

    private parseItem(item: any) {
        if (this.parsers && item) {
            for (let field in item) {
                if (this.parsers[field]) {
                    item[field] = this.parsers[field](item[field]);
                }
            }
        }

        return item;
    }

    private makeRequest(method: string, data?: any, headers?: any): Promise<any> {
       this.store.dispatch(setLoading(true));
        return axios({
            url: this.url,
            withCredentials: true,
            method,
            headers,
            data
        }).finally(() => {
            this.store.dispatch(setLoading(false));
        });
    }
}