import { useEffect } from "react";

export default function useEvent(target: EventTarget | null, name: string, callback: EventListener) {
    useEffect(() => {
        if (!target ) {
            return;
        }

        target.addEventListener(name, callback);

        return () => {
            target.removeEventListener(name, callback);
        }
    }, [target, name, callback]);
  }