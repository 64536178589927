import React, { ReactElement } from 'react';
import { useMemo } from 'react'

const SPLIT_REGEX = /(?:\r\n)|[\n\r]/g;
const headingRegex = /^(#{1,6})(.*)/;
const isListRegex = /^-.*?/;
interface Part {
    type: string,
    text?: string;
    children?: Part[];
}

const textParts = (text: string): Part[] => {
    const result: Part[] = [];
    const parts = text.split(SPLIT_REGEX);
    const length = parts.length;
    for (let idx = 0; idx < length; idx++) {
        const part = parts[idx];
        if (part && String(part).trim()) {
            if (headingRegex.test(part)) {
                const match: any = headingRegex.exec(part);
                result.push({
                    type: 'h' + match[1].length,
                    text: match[2]
                });
            } else if (isListRegex.test(part)) {
                const children: Part[] = [];
                let current = part;
                while (idx < length && (!current || isListRegex.test(current))) {
                    if (current) {
                        children.push({
                            type: 'li',
                            text: current.substring(1)
                        });
                    }
                    idx++;
                    current = parts[idx];
                }
                idx--;
                result.push({
                    type: 'ul',
                    children: children
                });
            } else {
                result.push({
                    type: 'p',
                    text: part
                });
            }
        }
    }

    return result;
}

const CLASS_NAMES: { [key: string]: string } = {
    p: 'paragraph'
};

function getReactElements(parts: Part[]): ReactElement[] {
    return parts.map((part: Part, index: number) => {
        const content  = part.children ? getReactElements(part.children) : part.text;
        return React.createElement(part.type, { key: index, className: CLASS_NAMES[part.type] }, content);
    });
}

export default function ParagraphList({ text }: { text: string}) {
    const parts = useMemo(() => textParts(text), [text]);

    const elements =  getReactElements(parts);

    return (
        <>
         {elements}
        </>
    )
}
