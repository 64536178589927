
import ServiceContext from '../../../../data/ServiceContext';
import { useContext } from 'react';
import MessageContext from '../../../../utils/messages/message-context';
import formProps from './form-props';
import ListEditForm from '../../../Helpers/ListView/ListEditForm';

export default function AddForm() {
    const messages = useContext(MessageContext);
    const { peopleService } = useContext(ServiceContext);
    const item = { name: '', jobTitle: '', description: '', image: '' };

    return (
        <ListEditForm data={item} onSave={peopleService.create}
            title={`${ messages.ADD } ${ messages.PERSON }`} {...formProps} />
    );
}
