
import { RootStore } from '../../../../data/store/store';
import { removeItem, createItem, updateItem, setItems } from './case-info.slice';
import { serviceUrl} from '../../../../data/utils/service-helpers';
import CrudService from '../../../../data/utils/crud.service';
import CaseInfo from './CaseInfo';

const URL = serviceUrl('case-info-api');
const actions = { setItems, updateItem, removeItem, createItem };
const parsers = { id: (value: any) => Number(value) };

export default class CaseInfoService extends CrudService<CaseInfo> {

    constructor(store: RootStore) {
        super(store, URL, actions, parsers);
    }
}