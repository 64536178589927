import React, { useContext } from 'react'
import MessageContext from '../../../utils/messages/message-context';
import { templateMessage } from '../../../utils/messages/messages';
import { WorkHours } from '../../../data/page-info/WorkHours';
import { useAppSelector } from '../../../data/store/store';
import { formatWorkDays } from '../../../utils/Intl/intl';
import LocaleContext from '../../../utils/Intl/LocaleContext';


export default function WorkHoursList() {
    const locale = useContext(LocaleContext);
    const messages = useContext(MessageContext);
    const info = useAppSelector(state => state.pageInfo);

    return (
        <div className="work-hours">
            <span>{messages.WORK_HOURS}:&nbsp;</span>
            <div className="work-hours-list">
                {
                    info.contacts.workHours && info.contacts.workHours.map((workHours: WorkHours, index: number) => (
                        <React.Fragment key={index}>
                            <div>
                                { formatWorkDays(workHours, locale) }
                            </div>
                            {workHours.ranges.map((range: string[], idx: number) => (
                                <div className="work-hour-times" key={idx}>
                                    { templateMessage(messages.RANGE, range)}
                                </div>
                            ))}
                        </React.Fragment>
                    ))
                }
            </div>
        </div>
    )
}
