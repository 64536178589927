
import { useAppSelector } from '../../../data/store/store';
import { UserRole } from './user-role';

export default function AdminContent({ children }: { children: JSX.Element}) {
    const role = useAppSelector(state => state.role);

    if (role === UserRole.Admin) {
        return children;
    }

    return null;
}
