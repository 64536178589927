import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { extend } from "../../utils/helpers";
import { ContactsInfo } from "./ContactsInfo";
import { HomeInfo } from "./HomeInfo";


export interface PageInfo {
    home: HomeInfo;
    contacts: ContactsInfo;
}

const infoSlice = createSlice({
    name: 'pageInfo',
    reducers: {
        setPageInfo: (_state, action: PayloadAction<PageInfo>) =>
            action.payload,
        updateHome: (state, action: PayloadAction<HomeInfo>) =>
            extend(state, {
                home: action.payload
            }),
        updateContacts: (state, action: PayloadAction<ContactsInfo>) =>
            extend(state, {
                contacts: action.payload
            })
    },
    initialState: {} as PageInfo
});

export const { setPageInfo, updateHome, updateContacts } = infoSlice.actions;

export default infoSlice;