
import { useState, useContext, useMemo } from 'react';
import ServiceContext from '../../../../data/ServiceContext';
import { useNavigate } from "react-router-dom";
import Form from '../../../Helpers/Form/Form';
import NotificationType from '../../../Helpers/Notification/NotificationType';
import MessageContext from '../../../../utils/messages/message-context';
import { Validator } from '../../../../data/model/validator';
import { accountRules } from '../../../../data/model/rules';

export default function CreateAccountForm() {
    const { loginService, notificationService } = useContext(ServiceContext);
    const messages = useContext(MessageContext);
    const [errors, setErrors] = useState<string[]>([]);
    const navigate = useNavigate();
    const newUser = { name: '', password: '', repeatPassword: '' };

    const validator = useMemo(() => new Validator(accountRules(messages)), [messages]);

    const inputs = {
        name: {
          type: 'input',
          label: messages.NAME,
          props: { autoComplete: 'nickname' }
        },
        password: {
          type: 'input',
          label: messages.PASSWORD,
          props: { type: 'password', autoComplete: 'new-password' }
        },
        repeatPassword: {
            type: 'input',
            label: messages.REPEAT_PASSWORD,
            props: { type: 'password', autoComplete: 'off' }
        }
    };

    function saveForm(data: any) {
        loginService.createUser(data).then((result: any) => {
            if (result?.data?.errors) {
                setErrors(result.data.errors);
            } else {
                navigate('/login');
                notificationService.show(NotificationType.Success, messages.SAVED_CHANGES);
            }
        }).catch(() => {
            notificationService.show(NotificationType.Error, messages.ERROR_OCCURED);
        });
    }

    return (
        <Form model={newUser} inputs={inputs} validator={validator} onSave={saveForm}
            saveText={messages.ENTER} modelErrors={errors}/>
    );
}