
import { useContext, useMemo } from 'react';
import IconButton from '../Button/IconButton';
import { CancelIcon } from '../Button/icons';
import MessageContext from '../../../utils/messages/message-context';
import { PopupOptions } from './popup-options';

import './popup.css';

export interface PopupProps {
    content?: JSX.Element | string;
    onClose: Function;
    options?: PopupOptions;
}

const BREAK_REGEX = /\n/g;

export default function Popup({ content, onClose, options = {} }: PopupProps) {
    const messages = useContext(MessageContext);
    const popupContent = useMemo(() => {
        if (options.breakContent && typeof content === 'string') {
            return content.split(BREAK_REGEX).map((sentence, index) => (
                <div key={index}>{sentence}</div>
            ));
        }

        return content;
    }, [content, options.breakContent]);

    return (
        <div className="overlay">
            <div className="popup">
                <div className="popup-top-actions">
                    <div className='actions-right'>
                        <IconButton IconElement={CancelIcon} props={{ title: messages.CLOSE }}
                            onClick={() => onClose({ text: messages.CLOSE })} />
                    </div>
                </div>
                <div className="popup-content">
                    {popupContent}
                </div>
                { options.actions &&
                    <div className="popup-bottom-actions">
                        <div className='actions'>
                            { options.actions.map((action: any) => (
                                <IconButton IconElement={action.icon} key={action.text} onClick={() => onClose(action) }>
                                    {action.text}
                                </IconButton>
                            )) }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
