import { useContext, useMemo } from 'react'
import MessageContext from '../../../utils/messages/message-context';
import { useAppSelector } from '../../../data/store/store';
import { UserRole } from '../User/user-role';
import Menu from '../../Helpers/Menu/Menu';
import Item from '../../Helpers/Menu/Item';

export default function Navigation() {
    const messages = useContext(MessageContext);
    const { role, cases, caseInfo } = useAppSelector(state => state);

    const items = useMemo(() => [
        { link: '/', text: messages.HOME },
        cases.length || role === UserRole.Admin ? { text: messages.CASES, link: '/cases' } : null,
        caseInfo.length || role === UserRole.Admin ? { text: messages.CASE_INFO, link: '/case-info' } : null,
        { link: '/contacts', text: messages.CONTACT },
        { link: '/about', text: messages.ABOUT }
    ].filter(item => item !== null) as Item[], [role, cases.length, caseInfo.length])

    return (
        <nav>
            <div className="logo-container">
                <img src="/assets/podiatry-reverse-removebg-preview.png" alt={`${messages.LOGO} - ${messages.PODIATRY_CENTER}`}
                    className="logo" />
            </div>

            <Menu wrapperClass="navigation" data={items} overflowTitle={messages.MORE_OPTIONS} />
        </nav>
    )
}
