import { useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import MessageContext from "../../utils/messages/message-context";
import ServiceContext from "../../data/ServiceContext";

export default function useBackConfirm(confirmMessage: string, requireConfirm?: boolean): Function[] {
    const messages = useContext(MessageContext);
    const { popupService } = useContext(ServiceContext);
    const navigate = useNavigate();
    const confirm = useRef(requireConfirm);

    return [(e: any) => {
        e.preventDefault();
        if (!confirm.current) {
            navigate(-1);
        } else {
            popupService.showConfirm(confirmMessage, messages.YES, messages.NO).then(() => {
                navigate(-1);
            });
        }
    }, () => confirm.current = true];
}