import { ElementType, SyntheticEvent, useState } from 'react';
import FormField from './FormField';
import { SaveIcon, CancelIcon } from '../Button/icons';
import IconButton from '../Button/IconButton';
import { extend, extendClean } from '../../../utils/helpers';
import { Validator } from '../../../data/model/validator';

import './form.css';

export interface InputOptions {
    type: string | ElementType,
    label?: string,
    props?: any;
    parse?: Function;
    valueDescription?: string;
}

export interface FormProps {
    model: any;
    validator?: Validator;
    inputs: { [key: string]: InputOptions};
    modelErrors?: string[];
    title?: string;
    saveText: string;
    cancelText?: string;
    onSave: (data: any) => void;
    onCancel?: Function;
    onChange?: Function;
}

export default function Form({ model, validator, inputs, modelErrors,
        title, saveText, cancelText, onSave, onCancel, onChange }: FormProps) {
    const [data, setData] = useState(model);
    const [errors, setErrors] = useState<any>({});
    const [hasChanges, setHasChanges] = useState(false);

    function saveForm(e: SyntheticEvent<HTMLFormElement>) {
        e.preventDefault();
        const errors: any = validator ? validator.validateModel(data) : {};
        setErrors(errors);

        if (Object.keys(errors).length === 0) {
            onSave(data);
        }
    }

    function onInputChange({ name, value }: any) {
        let errorMessage: string | undefined = '';
        if (validator) {
            errorMessage = validator.validateField(name, value, data);
            setErrors(extendClean(errors, { [name]: errorMessage}));
        }

        if (!(hasChanges || errorMessage)) {
            setHasChanges(true);
        }

        const current = extend(data, { [name]: value });
        setData(current);

        if (!errorMessage && onChange) {
            onChange(name, value);
        }
    }

    return (
        <form className="form" onSubmit={saveForm} noValidate>
            <fieldset>
                { title && <legend>{title}</legend> }
                { Object.keys(inputs).map(name => (
                    <FormField key={name} name={name} {...inputs[name]} InputElement={inputs[name].type} error={errors[name]}
                    value={data[name]} onChange={onInputChange} />
                )) }
                <div className="summary">
                    {modelErrors && modelErrors.map((error: string, index: number) => (
                        <div key={index} className="summary-error">{error}</div>
                    ))}
                </div>
                <div className="actions" >
                    <IconButton IconElement={SaveIcon} props={{ type: 'submit' }}
                        disabled={!hasChanges || Object.keys(errors).length > 0}>
                        {saveText}
                    </IconButton>
                    {onCancel &&
                        <IconButton IconElement={CancelIcon} onClick={onCancel}>
                        {cancelText}
                    </IconButton>
                    }
                </div>
            </fieldset>
        </form>
    )
}
