import ListEditForm from '../../../Helpers/ListView/ListEditForm';
import ServiceContext from '../../../../data/ServiceContext';
import { useContext } from 'react';
import MessageContext from '../../../../utils/messages/message-context';
import ItemNotFound from '../../../Helpers/Page/ItemNotFound';
import useFindById from '../../../Hooks/useFindById';
import formProps from './form-props';

export default function EditForm() {
    const { peopleService } = useContext(ServiceContext);
    const messages = useContext(MessageContext);
    const item = useFindById(state => state.people);

    if (!item) {
        return (<ItemNotFound />);
    }

    return (
        <ListEditForm data={item} onSave={peopleService.update}
            title={`${ messages.EDIT } ${ messages.PERSON }`} {...formProps} />
    );
}
