import CaseItem from './CaseItem';
import { useAppSelector } from '../../../data/store/store';
import { useContext } from 'react';
import ServiceContext from '../../../data/ServiceContext';
import ListView from '../../Helpers/ListView/ListView';
import './cases.css';
import MessageContext from '../../../utils/messages/message-context';

export default function CaseList() {
    const messages = useContext(MessageContext);
    const services = useContext(ServiceContext);
    const cases = useAppSelector(state => state.cases);

    return (
        <>
        <h1 className="page-title">{messages.CASES} - {messages.PODIATRY}</h1>
        <ListView service={services.caseService} ItemElement={CaseItem} data={cases} details  />
        </>
    );
}
