import { isNumber } from '../../utils/helpers';
import { APP_MESSAGES, templateMessage } from '../../utils/messages/messages';
import { range, required } from './validator';

const titleRule = (messages: APP_MESSAGES) =>
    ({ fn: required, message: `${messages.PLEASE_ENTER} ${messages.TITLE.toLowerCase()}` });

const descriptionRule = (messages: APP_MESSAGES) =>
    ({ fn: required, message: `${messages.PLEASE_ENTER} ${messages.DESCRIPTION.toLowerCase()}` });

export const homeRules = (messages: APP_MESSAGES) => {
    return {
        title: titleRule(messages),
        description: descriptionRule(messages)
    };
};

export const LATITUDE_MIN = -90;
export const LATITUDE_MAX = 90;
export const LONGITUDE_MIN = -180;
export const LONGITUDE_MAX = 180;

const inDayRange = (value: number) => 0 <= value && value < 24 * 60;

const toMinutes = (value: string) => {
    const time = value.split(':');
    return (Number(time[0]) || 0) * 60 + (Number(time[1]) || 0);
};

function workHoursRange({ startDay, endDay, ranges}: any): boolean {
    if ((!isNaN(endDay) && isNaN(startDay))) {
        return false;
    }

    if (!ranges || !ranges.length) {
        return !isNumber(startDay);
    }

    let previous: number[] | undefined;

    for (let idx = 0; idx < ranges.length; idx++) {
        const range = [toMinutes(ranges[idx][0]), toMinutes(ranges[idx][1])];
        if (!(inDayRange(range[0]) && inDayRange(range[1])) || range[1] < range[0]) {
            return false;
        }

        if (previous && range[0] < previous[1]) {
            return false;
        }
        previous = range;
    }

    return true;
}
function workHours(workHours: any[]): boolean {
    for (let idx = 0; idx < workHours.length; idx++) {
        if (!workHoursRange(workHours[idx])) {
            return false;
        }
    }

    return true;
}

export const contactRules = (messages: APP_MESSAGES) => {
    return {
        address: { fn: required, message: `${messages.PLEASE_ENTER} ${messages.ADDRESS.toLowerCase()}` },
        phone: { fn: required, message: `${messages.PLEASE_ENTER} ${messages.PHONE.toLowerCase()}` },
        workHours: [{ fn: workHours, message:
            `${templateMessage(messages.INVALID_VALUE, [messages.WORK_HOURS.toLowerCase()])}` }],
        latitude: [
            { fn: required, message: `${messages.PLEASE_ENTER} ${messages.LATITUDE.toLowerCase()}` },
            {
                fn: range(LATITUDE_MIN, LATITUDE_MAX),
                message: `${templateMessage(messages.INVALID_VALUE, [messages.LATITUDE.toLowerCase()])}`
            }],
        longitude: [
            { fn: required, message: `${messages.PLEASE_ENTER} ${messages.LONGITUDE.toLowerCase()}` },
            {
                fn: range(LONGITUDE_MIN, LONGITUDE_MAX),
                message: `${templateMessage(messages.INVALID_VALUE, [messages.LONGITUDE.toLowerCase()])}`
            }]
    };
};

const requireImage = (dataField: string) => ((value: any, data: any) => Boolean(data[dataField]) || required(value));

export const caseRules = (messages: APP_MESSAGES) => {
    return {
        title: titleRule(messages),
        description: descriptionRule(messages),
        image: { fn: requireImage('imageBeforeName'), message: `${messages.PLEASE_ENTER} ${messages.IMAGE.toLowerCase()}` },
        imageAfter: { fn: requireImage('imageAfterName'), message: `${messages.PLEASE_ENTER} ${messages.IMAGE.toLowerCase()}` }
    };
};

export const caseInfoRules = (messages: APP_MESSAGES) => {
    return {
        title: titleRule(messages),
        description: descriptionRule(messages),
        image: { fn: requireImage('imageName'), message: `${messages.PLEASE_ENTER} ${messages.IMAGE.toLowerCase()}` }
    };
};

export const aboutRules = (messages: APP_MESSAGES) => {
    return {
        name: { fn: required, message: `${messages.PLEASE_ENTER} ${messages.NAME.toLowerCase()}` },
        description: descriptionRule(messages),
        image: { fn: requireImage('imageName'), message: `${messages.PLEASE_ENTER} ${messages.IMAGE.toLowerCase()}` },
    };
};

export const accountRules = (messages: APP_MESSAGES) => {
    return {
        name: { fn: required, message: `${messages.PLEASE_ENTER} ${messages.NAME.toLowerCase()}` },
        password: { fn: required, message: `${messages.PLEASE_ENTER} ${messages.PASSWORD.toLowerCase()}` },
        repeatPassword: [{
            fn: required,
            message: messages.REPEAT_PASSWORD
        }, {
            fn: (value: string, data: any) => value === data.password,
            message: messages.PASSWORD_DOES_NOT_МАТCH
        }]
    };
};

export const changePasswordRules = (messages: APP_MESSAGES) => {
    return {
        password: { fn: required, message: `${messages.PLEASE_ENTER} ${messages.PASSWORD.toLowerCase()}` },
        newPassword: { fn: required, message: `${messages.PLEASE_ENTER} ${messages.PASSWORD.toLowerCase()}` },
        repeatPassword: [{
            fn: required,
            message: messages.REPEAT_PASSWORD
        }, {
            fn: (value: string, data: any) => value === data.newPassword,
            message: messages.PASSWORD_DOES_NOT_МАТCH
        }]
    };
};