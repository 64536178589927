import BackButton from '../Button/BackButton';
import { useContext } from 'react';
import MessageContext from '../../../utils/messages/message-context';

export default function NoMatch() {
    const messages = useContext(MessageContext);
    if (typeof global !== 'undefined') {
        (global as any).pageNotFound = true;
    }

    return (
        <>
            <h3>
                { messages.PAGE_NOT_FOUND}
            </h3>
            <BackButton />
        </>
    );
}
