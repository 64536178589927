
import { useAppSelector } from '../../../data/store/store';
import './loading.css';

export default function Loading() {
    const loading = useAppSelector(state => state.loading);

    if (loading > 0) {
        return (
            <div className="loading">
            </div>
        );
    }

    return null;
}
