import { APP_MESSAGES } from "../../../../utils/messages/messages";
import Upload from "../../../Helpers/Form/Upload";
import { aboutRules } from "../../../../data/model/rules";
import PersonView from "../PersonView";
import { IMAGE_FIELDS } from "../constants";

const createInputs = (messages: APP_MESSAGES) => ({
    name: {
      type: 'input',
      label: messages.NAME
    },
    jobTitle: {
      type: 'input',
      label: messages.JOB_TITLE
    },
    description: {
      type: 'textarea',
      label: messages.DESCRIPTION,
      props: { rows: 8 }
    },
    image: {
      type: Upload,
      props: { text: messages.IMAGE }
    }
});

const formProps = {
    createInputs,
    rules: aboutRules,
    imageFields: IMAGE_FIELDS,
    ItemElement: PersonView,
    previewItemClass: 'person-preview',
    listClass: 'person-form-list listview'
};

export default formProps;