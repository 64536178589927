
import { useContext } from 'react';
import ListEditForm from '../../../Helpers/ListView/ListEditForm';
import MessageContext from '../../../../utils/messages/message-context';
import ServiceContext from '../../../../data/ServiceContext';
import formProps from './form-props';

export default function AddForm() {
    const messages = useContext(MessageContext);
    const { caseInfoService } = useContext(ServiceContext);
    const item = { title: '', description: '', imageName: '' };

    return (
        <ListEditForm data={item} onSave={ caseInfoService.create }
            title={`${ messages.ADD} ${messages.CASE_INFO}`} {...formProps} />
    )
}
