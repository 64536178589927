
import { useContext, useMemo } from 'react';
import BackButton from '../../Helpers/Button/BackButton';
import MessageContext from '../../../utils/messages/message-context';
import TabStrip from '../../Helpers/TabStrip/TabStrip';
import ChangePasswordForm from './forms/ChangePasswordForm';
import CreateAccountForm from './forms/CreateAccountForm';

export default function Account() {
    const messages = useContext(MessageContext);
    const items = useMemo(() => [messages.CHANGE_PASSWORD, messages.CREATE_ACCOUNT], [messages]);

    return (
        <>
            <BackButton/>
            <TabStrip items={items}>
                <ChangePasswordForm />
                <CreateAccountForm/>
            </TabStrip>
        </>
    );
}