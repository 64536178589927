import { last } from "../../../utils/helpers";
import Item from "./Item";

const GAP = 5;

export const updateOverflowItems = ({ contentWidth, itemWidths, children, data }:
  { contentWidth: number, itemWidths: number[], children: HTMLElement[], data: Item[] }) => {
  const result: Item[] = [];
  let overflowSize = last(itemWidths);
  let sum = 0;

  if (!itemWidths.length) {
    for (let idx = 0; idx < children.length; idx++) {
      children[idx].classList.remove('hidden');
    }

    for (let idx = 0; idx < children.length; idx++) {
      itemWidths.push(children[idx].offsetWidth);
    }

    overflowSize = last(itemWidths);
  }

  for (let idx = 0; idx < data.length; idx++) {
    const child = children[idx];
    sum += itemWidths[idx];

    const maxWidth = idx === data.length - 1 ? contentWidth : contentWidth - overflowSize;
    if (sum > maxWidth) {
      child.classList.add('hidden');
      result.push(data[idx]);
    } else {
      child.classList.remove('hidden');
    }

    sum += GAP;
  }

  last(children).classList.toggle('hidden', !result.length);

  return result;
}