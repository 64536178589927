import { useContext } from "react";
import { imageUrl } from "../../../data/utils/service-helpers";
import MessageContext from "../../../utils/messages/message-context";
import { CASE_INFO_DIRECTORY } from "./constants";
import CaseInfo from "./data/CaseInfo";

export default function CaseInfoView({ description, title, image, imageName }: CaseInfo) {
    const messages = useContext(MessageContext);
    return (
        <div className="case-item">
            { (image || imageName) && <img className="case-image"
                src={ image || imageUrl(imageName, CASE_INFO_DIRECTORY) } alt={`${messages.IMAGE} ${title}`} /> }
            <div className="case-description">
                <h2 className="secondary-title">{ title }</h2>
                <p>{ description }</p>
            </div>
        </div>
    );
}
