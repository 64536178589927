import { useCallback, useMemo, useState } from "react";
import OverflowButton from "./OverflowButton";
import MenuItem from "./MenuItem";
import Item from './Item';
import useResizeObserver from "../../Hooks/useResizeObserver";
import useEvent from "../../Hooks/useEvent";
import './menu.css';
import { isServerRender } from "../../../utils/helpers";
import { updateOverflowItems } from "./utils";


export default function Menu({ data, wrapperClass, overflowTitle }:
  { data: Item[], wrapperClass?: string, overflowTitle: string }) {
    const [overflowItems, setOverflowItems] = useState<Item[]>([]);
    const [overflowOpen, setOverflowOpen] = useState(false);

    const itemWidths = useMemo<number[]>(() => [], [data]);

    const onResize = useCallback((entries: ResizeObserverEntry[]) => {
      if (!menuRef.current || isServerRender) {
        return;
      }

      const newOverflow: Item[] = updateOverflowItems({
        contentWidth: entries[0].contentRect.width,
        children: menuRef.current.children,
        data,
        itemWidths
      });

      setOverflowOpen(false);
      setOverflowItems((current) => {
        if (newOverflow.length !== current.length) {
          return newOverflow;
        }
        return current;
      });
    }, [itemWidths]);

    const menuRef: React.RefObject<any> = useResizeObserver(onResize);

    const onDocumentClick = useCallback((e: any) => {
      setOverflowOpen((isOpen) => {
          return isOpen && Boolean(e.target.closest('.menu-overflow-button'));
      });
    }, []);

    useEvent(isServerRender ? null : document, 'click', onDocumentClick);

    const items = useMemo(() => data.map((item, index) => <MenuItem key={index} item={item} />), [data]);
    const overflow = useMemo(() =>
      overflowItems.map((item, index) => <MenuItem key={index} item={item} />),
      [overflowItems]);

    const overflowClick = useCallback(() => {
      setOverflowOpen((isOpen) => {
        return !isOpen;
    });
    }, []);

    const classNames = ['menu', wrapperClass].filter(name => name).join(' ');

    return (
      <ul className={classNames} ref={menuRef}>
        {items}
        <li className='menu-overflow-item hidden'>
          <OverflowButton onClick={overflowClick} title={overflowTitle} />
          {overflowOpen ? (
            <div className="menu-popup-wrapper">
              <div className='menu-popup'>
                <ul>{overflow}</ul>
              </div>
            </div>
          ) : ''}
        </li>
      </ul>
    );
  }