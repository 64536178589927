import { createSlice, PayloadAction, SliceCaseReducers, Draft  } from '@reduxjs/toolkit';
import { ListItem } from '../../Components/Helpers/ListView/ListItem';
import { extend } from '../../utils/helpers';

export const createCrudSlice = <T extends ListItem>(name: string) => createSlice<T[], SliceCaseReducers<T[]>>({
    name,
    reducers: {
        removeItem: (state: Draft<T[]>, action: PayloadAction<T>) => {
            return state.filter((item: Draft<T>) => item.id !== action.payload.id);
        },
        createItem: (state: Draft<T[]>, action: PayloadAction<Draft<T>>) => {
            return state.concat(action.payload);
        },
        updateItem: (state: Draft<T[]>, action: PayloadAction<T>) => {
            return state.map((item: Draft<T>) => {
                if (item.id === action.payload.id) {
                    return extend(item, action.payload);
                }

                return item;
            });
        },
        setItems: (_state: any, action: PayloadAction<T[]>) => {
            return action.payload;
        }
    },
    initialState: [] as T[]
});