import { isNumber } from "../helpers";



const LOCALES: { [key: string]: any[]} = {};
const START_DATE = new Date(1800, 0, 5);
const DAYS_IN_WEEK = 7;
const TIME_PER_DAY = 1000 * 60 * 60 * 24;

export function getLocaleWeekDays(locale: string) {
    if (LOCALES[locale]) {
        return LOCALES[locale];
    }

    const days: any[] = [];
    const dateFormat = new Intl.DateTimeFormat(locale, { weekday: 'long' });
    const firstDay: number = (new Intl.Locale(locale) as any).weekInfo.firstDay;

    for (let idx = 0; idx < DAYS_IN_WEEK; idx++) {
        const index = (idx + firstDay) % DAYS_IN_WEEK;
        const date = new Date(START_DATE.getTime() + index * TIME_PER_DAY);
        days.push({
            name: dateFormat.format(date),
            index
        });
    }

    LOCALES[locale] = days;

    return days;
}


export function formatWorkDays({ startDay, endDay }: { startDay: number, endDay: number }, locale: string) {
    let days = new Date(START_DATE.getTime() + startDay * TIME_PER_DAY)
        .toLocaleString(locale, { weekday: 'long' });
    if (!isNaN(endDay) && isNumber(endDay)) {
        const end = new Date(START_DATE.getTime() + endDay * TIME_PER_DAY)
            .toLocaleString(locale, { weekday: 'long' });
        days += ` - ${end}`;
    }

    return`${days}: `;
}