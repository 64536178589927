
export const isEmpty = (value: any) =>
    value === null || value === undefined || value === '';

export const extend = (currentData: any, newData: any) => Object.assign({}, currentData, newData);

export const defined = (value: any) => typeof value !== 'undefined';
export const isNumber = (value: any) => typeof value === 'number';
export const isServerRender = typeof window === 'undefined';
export const last = (arr: any[]) => arr[arr.length - 1];

export function extendClean(currentData: any, newData: any) {
    const result: any = extend(currentData, newData);
    Object.entries(newData).forEach(([field, value]) => {
        if (value === null || value === undefined) {
            delete result[field];
        }
    });

    return result;
}

export function classNames(classes: any): string {
    return Object.keys(classes).reduce<string[]>((acc, value) => {
        if (classes[value]) {
            acc.push(value);
        }
        return acc;
    }, []).join(' ');
}

export function readBase64(file: any, callback: (base64: string) => void) {
    const fileReader = new FileReader();

    fileReader.addEventListener('load', (result: any) => {
        callback(String(fileReader.result));
    });
    fileReader.readAsDataURL(file);
}