import { useAppSelector } from '../../../data/store/store';
import { useContext } from 'react';
import ServiceContext from '../../../data/ServiceContext';
import ListView from '../../Helpers/ListView/ListView';
import CaseInfoView from './CaseInfoView';
import './case-info.css';
import MessageContext from '../../../utils/messages/message-context';

export default function CaseInfoList() {
    const messages = useContext(MessageContext);
    const { caseInfoService } = useContext(ServiceContext);
    const caseInfo = useAppSelector(state => state.caseInfo);

    return (
        <>
            <h1 className="page-title">{messages.CASE_INFO} - {messages.PODIATRY}</h1>
            <ListView service={caseInfoService} ItemElement={CaseInfoView} data={caseInfo} details={true}  />
        </>
    );
}
