import ListEditForm from '../../../Helpers/ListView/ListEditForm';
import ServiceContext from '../../../../data/ServiceContext';
import { useContext } from 'react';
import MessageContext from '../../../../utils/messages/message-context';
import formProps from './form-props';

export default function AddForm() {
    const messages = useContext(MessageContext);
    const { caseService } = useContext(ServiceContext);
    const item = { title: '', description: '', image: '', imageAfter: '', imageBeforeName: '', imageAfterName: '' };

    return (
        <ListEditForm data={item} onSave={ caseService.create }
            title={`${ messages.ADD} ${messages.CASE}`} {...formProps} />
    );
}
