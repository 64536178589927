import { useContext } from "react";
import BackButton from "../Button/BackButton";
import MessageContext from "../../../utils/messages/message-context";
import { Helmet } from "react-helmet";

export default function ItemNotFound() {
    const messages = useContext(MessageContext);

    if (typeof global !== 'undefined') {
        (global as any).pageNotFound = true;
    }

    return (
        <>
            <Helmet>
                <title>{messages.ITEM_NOT_FOUND} - {messages.PODIATRY_CENTER}</title>
            </Helmet>
            <div>
                <BackButton />
                <h2>{messages.ITEM_NOT_FOUND}</h2>
            </div>
        </>
    )
}
