
import { useAppSelector } from "../../../data/store/store";
import NotificationType from "./NotificationType";
import NotificationItem from "./NotificationItem";
import { useContext } from "react";
import ServiceContext from "../../../data/ServiceContext";
import AnimationContainer from "./AnimationContainer"
import { classNames } from "../../../utils/helpers";

import './notification.css';

export default function NotificationContainer() {
    const notifications = useAppSelector(state => state.notifications);
    const { notificationService } = useContext(ServiceContext);

    return (
        <div className='notification-container'>
            { notifications.map((item: NotificationItem) => (
                <AnimationContainer key={item.id} show={true} remove={item.hide}
                    onEnd={() => notificationService.remove(item)}>
                    <div className={classNames({
                            'notification': true,
                            'error-notification': item.type === NotificationType.Error,
                            'success-notification': item.type === NotificationType.Success
                        })}>
                        { item.content }
                    </div>
                </AnimationContainer>
            ))}
        </div>
    );
}