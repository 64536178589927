import { useAppSelector } from '../../../data/store/store';
import { useContext, useMemo } from 'react';
import { Validator } from '../../../data/model/validator';
import ServiceContext from '../../../data/ServiceContext';
import RemoteForm from '../../Helpers/Form/RemoteForm';
import { isEmpty } from '../../../utils/helpers';
import useBackConfirm from '../../Hooks/useBackConfirm';
import BackButton from '../../Helpers/Button/BackButton';
import { templateMessage } from '../../../utils/messages/messages';
import { contactRules, LATITUDE_MIN, LATITUDE_MAX, LONGITUDE_MIN, LONGITUDE_MAX } from '../../../data/model/rules';

import WorkHours from '../WorkHours/WorkHours';
import MessageContext from '../../../utils/messages/message-context';

const parseNumber = (value: string) => !isEmpty(value) ? parseFloat(value) : '';

export default function ContactsForm() {
    const info = useAppSelector(state => state.pageInfo);
    const messages = useContext(MessageContext);
    const { infoService } = useContext(ServiceContext);
    const [confirm, enableConfirm] = useBackConfirm(messages.UNSAVED_CHANGES);

    const validator = useMemo(() => new Validator(contactRules(messages)), [messages]);

    const inputs = useMemo(() => ({
      address: {
        type: 'input',
        label: messages.ADDRESS
      },
      phone: {
        type: 'input',
        label: messages.PHONE,
        props: { type: 'tel' }
      },
      latitude: {
        type: 'input',
        label: messages.LATITUDE,
        props: { type: 'number', min: LATITUDE_MIN, max: LATITUDE_MAX },
        parse: parseNumber,
        valueDescription: templateMessage(messages.VALUE_BETWEEN, [LATITUDE_MIN, LATITUDE_MAX])
      },
      longitude: {
        type: 'input',
        label: messages.LONGITUDE,
        props: { type: 'number', min: LONGITUDE_MIN, max: LONGITUDE_MAX },
        parse: parseNumber,
        valueDescription: templateMessage(messages.VALUE_BETWEEN, [LONGITUDE_MIN, LONGITUDE_MAX])
      },
      workHours: {
        type: WorkHours,
        label: messages.WORK_HOURS
      }
  }), [messages]);

    const onSave = (data: any) => infoService.updateContacts(data);

    return (
        <>
          <BackButton onClick={confirm}/>
          <RemoteForm model={info.contacts} inputs={inputs} validator={validator}
            title={`${messages.EDIT} ${ messages.CONTACT }`}
            onSave={onSave} saveText={messages.SAVE} onChange={enableConfirm}
            cancelText={messages.CANCEL} onCancel={confirm} />
        </>
    );
}
