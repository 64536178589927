
import { v4 } from 'uuid';
import { RootStore } from '../../../data/store/store';
import { addNotification, hideNotification, removeNotification } from './notification-slice';
import NotificationItem from './NotificationItem';
import NotificationType from './NotificationType';

const DEFAULT_DURATION = 2000;

export default class NotificationService {
    private store: RootStore;

    constructor(store: RootStore) {
        this.store = store;
    }

    public show(type: NotificationType, content: string, duration: number = DEFAULT_DURATION) {
        const id = v4();
        const item = { type, content, duration, id };
        this.store.dispatch(addNotification(item));

        setTimeout(() => {
            this.store.dispatch(hideNotification(item));
        }, duration);
    }

    public remove(item: NotificationItem) {
        this.store.dispatch(removeNotification(item));
    }
}
