import { RootStore } from '../../../data/store/store';
import axios from 'axios';
import { UserRole } from './user-role';
import { setRole } from './role-slice';
import SERVICE_URL from '../../../data/utils/service-url';

const instance = axios.create({
    baseURL: SERVICE_URL
});

export default class LoginService {
    private store: RootStore;
    private checkingRole: boolean = false;

    constructor(store: RootStore) {
        this.store = store;
    }

    checkRole() {
        if (this.checkingRole) {
            return;
        }
        this.checkingRole = true;
        instance.post('check-user').then(result => {
            this.setRole(result.data.role);
            this.checkingRole = false;
        });
    }

    loginUser(userData: any) {
        return instance.post('login', userData).then(result => {
            this.setRole(result.data.role);
        });
    }

    logoutUser() {
        return instance.post('logout').then(result => {
            this.store.dispatch(setRole(UserRole.Anonymous));
        });
    }

    createUser(userData: any) {
        return instance.post('create-user', userData);
    }

    changePassword(userData: any) {
        return instance.post('change-password', userData);
    }

    resetRole() {
        this.store.dispatch(setRole(UserRole.Anonymous));
    }

    private setRole(role: string) {
        const result = role === 'admin' ? UserRole.Admin : UserRole.Anonymous;
        this.store.dispatch(setRole(result));
    }
}