

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export function isEmpty(value: any) {
    return value === null || value === undefined || value === '';
}

export const required = (value: any) => !isEmpty(value);
export const email = (value: string) => EMAIL_REGEX.test(value);
export const range = (start: number, end: number ) =>
    (value: number) => isEmpty(value) || (start <= value && value <= end);

export interface Rule {
    fn: (value: any, data?: any) => boolean;
    message: string;
}

export interface Rules {
    [field: string]: Rule[] | Rule
}

export interface ValidationResult {
    [field: string]: string;
}

function getArray<T>(value: T): T[] {
    return Array.isArray(value) ? value : [value];
}

export class Validator {
    rules: Rules;

    constructor(rules: Rules) {
        this.rules = rules;
    }

    validateField(field: string, value: any, model: any): string | undefined {
        const rules = this.rules[field];
        if (!rules) {
            return;
        }

        const rulesArr = getArray(rules) as Rule[];
        for (let idx = 0; idx < rulesArr.length; idx++) {
            const rule = rulesArr[idx];
            if (!rule.fn(value, model)) {
                return rule.message;
            }
        }
    }

    validateModel(data: any): ValidationResult {
        const result: ValidationResult = {};
        Object.keys(this.rules).forEach(field => {
            const error = this.validateField(field, data[field], data);
            if (error) {
                result[field] = error;
            }
        });

        return result;
    }
}