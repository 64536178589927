import { useContext, useMemo } from "react";
import DropDown from "../../Helpers/Form/DropDown";
import { getLocaleWeekDays } from "../../../utils/Intl/intl";
import LocaleContext from "../../../utils/Intl/LocaleContext";

export default function DayPicker({ value, onChange }: { value: number, onChange: Function}) {
    const locale = useContext(LocaleContext);

    function onDayChange({ item }: any) {
        onChange(item ? item.index : '');
    }

    const days = useMemo(() => getLocaleWeekDays(locale), [locale]);

    return (
        <DropDown data={days} value={value} textField="name" valueField="index" onChange={onDayChange} />
    );
}
