
export default function TimeRange({ range, onChange }: { range: string[], onChange: Function}) {
    const valueChange = (e: any, index: number) => {
        const newValue = range.slice(0);
        newValue[index] = e.target.value;

        onChange(newValue);
    };

    return (
        <>
            <input type="time" max={range[1]} className="input"
                value={range[0]} onChange={(e: any) => valueChange(e, 0)}/>
            &nbsp;-&nbsp;
            <input type="time" className="input"
                value={range[1]} min={range[0]} onChange={(e: any) => valueChange(e, 1)}/>
        </>
    );
}