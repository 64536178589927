import { ChangeEvent, useState } from "react";
import { readBase64 } from "../../../utils/helpers";

export interface UploadProps {
  onChange: (e: { value: File | null }) => void;
  text: string;
  preview?: boolean;
  image?: string;
}

export default function Upload({ onChange, text, preview, image }: UploadProps) {
    const [previewImage, setPreviewImage] = useState(image);
    function onFileChange(e: ChangeEvent<HTMLInputElement>) {
      if (e.target.files && e.target.files.length) {
        onChange({
            value: e.target.files[0]
        });

        if (preview) {
          readBase64(e.target.files[0], (base64: string) => setPreviewImage(base64));
        }
      }
    }

    return (
      <div className="upload-wrapper">
        <label className="button">
          { text }
          <input type="file" style={{ display: 'none' }} onChange={onFileChange} accept="image/*" />
        </label>
      {preview && previewImage &&
        <img src={previewImage} alt={text} className="upload-preview" />
      }
      </div>
    );
}
