
import { useState, useContext, useMemo } from 'react';
import ServiceContext from '../../../data/ServiceContext';
import { useNavigate } from "react-router-dom";
import Form from '../../Helpers/Form/Form';
import { required, Validator } from '../../../data/model/validator';
import NotificationType from '../../Helpers/Notification/NotificationType';
import BackButton from '../../Helpers/Button/BackButton';
import MessageContext from '../../../utils/messages/message-context';

export default function Login() {
    const { loginService, notificationService } = useContext(ServiceContext);
    const messages = useContext(MessageContext);
    const [errors, setErrors] = useState<string[]>([]);
    const navigate = useNavigate();
    const user = { name: '', password: '' };

    const validator = useMemo(() => new Validator({
        name: { fn: required, message: `${messages.PLEASE_ENTER} ${messages.NAME.toLowerCase()}` },
        password: { fn: required, message: `${messages.PLEASE_ENTER} ${messages.PASSWORD.toLowerCase()}` }
    }), [messages]);

    const inputs = {
        name: {
          type: 'input',
          label: messages.NAME,
          props: { autoComplete: 'nickname' }
        },
        password: {
          type: 'input',
          label: messages.PASSWORD,
          props: { type: 'password', autoComplete: 'current-password' }
        }
    };

    function saveForm(data: any) {
        loginService.loginUser(data).then(() => {
            navigate('/');
        }).catch(result => {
            if (result.response.status === 401) {
                setErrors([messages.WRONG_CREDENTIALS]);
            } else {
                notificationService.show(NotificationType.Error, messages.ERROR_OCCURED);
            }
        });
    }

    return (
        <>
            <BackButton/>

            <Form model={user} title={messages.LOGIN} inputs={inputs} validator={validator}  onSave={saveForm}
                 saveText={messages.ENTER} modelErrors={errors}/>
        </>
    );
}