import { createSlice, PayloadAction, SliceCaseReducers } from "@reduxjs/toolkit";
import NotificationItem from "./NotificationItem";

const notificationSlice = createSlice<NotificationItem[], SliceCaseReducers<NotificationItem[]>>({
    name: 'notifications',
    reducers: {
        addNotification: (state, action: PayloadAction<NotificationItem>) => {
            return state.concat(action.payload);
        },
        removeNotification: (state, action: PayloadAction<NotificationItem>) => {
            return state.filter(item => item.id !== action.payload.id);
        },
        hideNotification: (state, action: PayloadAction<NotificationItem>) => {
            return state.map(item => {
                if (item.id === action.payload.id) {
                    return Object.assign({}, item, { hide: true });
                }
                return item;
            });
        }
    },
    initialState: []
});

export const { addNotification, removeNotification, hideNotification } = notificationSlice.actions;

export default notificationSlice;