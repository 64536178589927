import { NavLink } from "react-router-dom";
import Item from './Item';

const activeLinkClass = ({isActive }: any) => isActive ? 'active-link' : '';

const MenuItem = ({ item }: { item: Item }) => {
    return (
        <li>
            <NavLink className={activeLinkClass} to={item.link}>{item.text}</NavLink>
        </li>
    );
}

export default MenuItem;