import { LatLngTuple } from "leaflet";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import { EditIcon } from "../../Helpers/Button/icons";
import EditButton from "../../Helpers/Button/EditButton";
import { useAppSelector } from "../../../data/store/store";
import AdminContent from "../User/AdminContent";

import './contacts.css';
import WorkHoursList from '../WorkHours/WorkHoursList';
import { useContext } from "react";
import MessageContext from "../../../utils/messages/message-context";

//const position: LatLngTuple = [42.63978, 23.38625];

export default function Contacts() {
    const messages = useContext(MessageContext);
    const info = useAppSelector(state => state.pageInfo);
    const position = [info.contacts.latitude, info.contacts.longitude] as LatLngTuple;

    return (
        <div className="contact-wrapper">
            <h1 className="page-title">{messages.CONTACT_US}</h1>
            <AdminContent>
                <EditButton path="/contacts/edit" message={messages.EDIT} icon={EditIcon}/>
            </AdminContent>
            <div className="contact">
                <div className="address">
                    <h4>
                        <span>{messages.ADDRESS}:&nbsp;</span><span>{ info.contacts.address }</span>
                    </h4>
                    <h5>
                        <span>{messages.PHONE}:&nbsp;</span><span>{ info.contacts.phone }</span>
                    </h5>
                    <h5>
                        <WorkHoursList />
                    </h5>
                </div>

                <MapContainer center={position} zoom={16} className="map-container">
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={position}>
                    </Marker>
                </MapContainer>
            </div>
        </div>
    );
}
