

import { setLoading, RootStore } from '../store/store';
import axios from 'axios';
import { serviceUrl, createFormData, FORM_DATA_HEADERS } from '../utils/service-helpers';
import { updateHome, updateContacts, setPageInfo } from './page-info.slice';
import { HomeInfo } from './HomeInfo';
import { ContactsInfo } from './ContactsInfo';

const instance = axios.create({
    withCredentials: true
});

export default class InfoService {
    private store: RootStore;

    constructor(store: RootStore) {
        this.store = store;
    }

    load() {
        axios.get(serviceUrl('info')).then(response => {
            this.store.dispatch(setPageInfo(response.data));
        });
    }

    updateHome = (data: HomeInfo): Promise<any> => {
        return this.makeRequest('home', createFormData(data), FORM_DATA_HEADERS).then(response => {
            if (!response.data?.errors) {
                this.store.dispatch(updateHome(response.data));
            }
            return response;
        });
    }

    updateContacts = (data: ContactsInfo): Promise<any> => {
        return this.makeRequest('contacts', data).then(response => {
            if (!response.data?.errors) {
                this.store.dispatch(updateContacts(response.data));
            }
            return response;
        });
    }

    private makeRequest(path: string, data?: any, headers?: any): Promise<any> {
        this.store.dispatch(setLoading(true));
        return instance({
            url: serviceUrl(path),
            method: 'PUT',
            data,
            headers
        }).finally(() => {
            this.store.dispatch(setLoading(false));
        });
    }
}