import { useContext } from 'react';
import MessageContext from '../../../utils/messages/message-context';
import { imageUrl } from '../../../data/utils/service-helpers';
import { CASE_DIRECTORY } from './constants';
import Case from './data/Case';


export default function CaseItem({ id, description, title, image, imageAfter, imageBeforeName, imageAfterName}: Case) {
    const messages = useContext(MessageContext);

    return (
        <div className="case-item">
            { (image || imageBeforeName) && <img className="case-image"
                src={ image || imageUrl(imageBeforeName, CASE_DIRECTORY) } alt={`${ messages.BEFORE } ${ title }`} /> }
            <div className="case-description">
                <h2 className="secondary-title">{ title }</h2>
                <p>{ description }</p>
            </div>
            { (imageAfter || imageAfterName) && <img className="case-image"
                src={ imageAfter || imageUrl(imageAfterName, CASE_DIRECTORY) } alt={`${ messages.AFTER } ${ title }`} /> }
        </div>
    );
}
