

export default function IconLink({IconElement, onClick, href, children, disabled, props}: any) {
    return (
        <a href={href} onClick={onClick} className={`icon-link ${disabled ? 'disabled' : ''}`} {...props}>
            { IconElement && <IconElement className="icon"/> }
            {children}
        </a>
    )
}
