

const BACK = 'Назад';
const DELETE = 'Изтрий';
const ADD = 'Добави';
const SAVE = 'Запази';
const CANCEL = 'Откажи';
const EDIT = 'Промени';
const LOGIN = 'Вход';
const ENTER = 'Влез';
const UNSAVED_CHANGES = 'Ще загубите текущите промени.\n Сигурни ли сте че искате да продължите?';
const HOME = 'Начало';
const CASE = 'Случай';
const CASES = 'Случаи';
const PODIATRY = 'Podiatry';
const PODIATRY_CENTER = 'Podiatry Center';
const LOGO = 'Лого';
const CONTACT = 'За Контакт';
const CONTACT_US = 'Свържи се с нас';
const ABOUT = 'За Нас';
const WHO_ARE_WE = 'Кои сме ние';
const WRONG_CREDENTIALS = 'Невалидно име или парола';
const NAME = 'Име';
const PASSWORD = 'Парола';
const PLEASE_ENTER = 'Моля въведете';
const TITLE = 'Заглавие';
const DESCRIPTION = 'Описание';
const IMAGE = 'Картинка';
const REQUIRES_LOGIN = 'Страницата изисква вход';
const ACTION_REQUIRES_LOGIN = 'Действитео изисква вход';
const ERROR_OCCURED = 'Възникна грешка';
const PAGE_NOT_FOUND = 'Страницата не може да бъде намерена';
const CONFIRM_DELETE = 'Изтрий този итем?';
const PHONE = 'Телефон';
const EMAIL = 'Имейл';
const ADDRESS = 'Адрес';
const SESSION_EXPIRED = 'Текущата сесия е изтекла.\n Моля влезте отново.';
const LOG_OUT = 'Изход';
const SAVED_CHANGES = 'Промените бяха запазени';
const YES = 'Да';
const NO = 'Не';
const CLOSE = 'Затвори';
const OK = 'Добре';
const VALID = 'валлиден';
const LATITUDE = 'Ширина';
const LONGITUDE = 'Височина';
const VALUE_BETWEEN = 'Стойност между {0} и {1}';
const INVALID_VALUE = 'Невалидна стойност за {0}';
const SITE_TITLE = 'Podiatry Center - Foot Care Service';
const PERSON = 'Човек';
const WORK_HOURS = 'Работно Време';
const RANGE = '{0} до {1}';
const DAYS = 'дни';
const HOURS = 'часове';
const FOLLOW_US_IN = 'Последвай ни в';
const BEFORE = 'Преди';
const AFTER = 'След';
const DETAILS = 'Подробности';
const CASE_INFO = 'Полезна Информация';
const ITEM_NOT_FOUND = 'Елементът не беше намерен';
const MORE_INFORMATION = 'Повече информация';
const JOB_TITLE = 'Професия';
const ACCOUNT = 'Профил';
const REPEAT_PASSWORD = 'Повтори паролата';
const PASSWORD_DOES_NOT_МАТCH = 'Паролата не съвпадата';
const NAME_NOT_UNIQUE = 'Името трябва да е уникално.';
const CHANGE_PASSWORD = 'Промени паролата';
const CREATE_ACCOUNT = 'Създай профил';
const NEW_PASSWORD = 'Нова парола';
const MORE_OPTIONS = 'Още';

const TEXT_EDITOR_DESCRIPTION = 'Нов ред за параграф. n пъти # за заглавие h{n} в началото на ред. например: ###Title за <h3>Title</h3>. тире(-) в началото на ред за лист';

export const MESSAGES: APP_MESSAGES = {
    BACK, DELETE, ADD, SAVE, CANCEL, EDIT, LOGIN, UNSAVED_CHANGES,
    HOME, CASES, CONTACT, ABOUT, WRONG_CREDENTIALS, NAME, PASSWORD, ENTER, CHANGE_PASSWORD,
    TITLE, DESCRIPTION, IMAGE, PLEASE_ENTER, REQUIRES_LOGIN, PAGE_NOT_FOUND, CREATE_ACCOUNT, NEW_PASSWORD,
    CONFIRM_DELETE, PHONE, EMAIL, ADDRESS, SESSION_EXPIRED, ACCOUNT, REPEAT_PASSWORD, PASSWORD_DOES_NOT_МАТCH,
    LOG_OUT, CASE, ACTION_REQUIRES_LOGIN, ERROR_OCCURED, SAVED_CHANGES, YES, NO, NAME_NOT_UNIQUE,
    CLOSE, OK, VALID, LATITUDE, LONGITUDE, INVALID_VALUE, VALUE_BETWEEN, SITE_TITLE, PERSON, MORE_OPTIONS,
    WORK_HOURS, RANGE, DAYS, HOURS, FOLLOW_US_IN, BEFORE, AFTER, DETAILS, CASE_INFO, ITEM_NOT_FOUND,
    MORE_INFORMATION, PODIATRY, CONTACT_US, WHO_ARE_WE, LOGO, TEXT_EDITOR_DESCRIPTION, JOB_TITLE, PODIATRY_CENTER
};

const REPLACE_REGEX = /\{(\d+)\}/g;

export const templateMessage = (message: string, values: any[]) =>
    message.replace(REPLACE_REGEX, (_match: string, index: number) => values[index]);

export interface APP_MESSAGES {
    BACK: string;
    DELETE: string;
    ADD: string;
    SAVE: string;
    CANCEL: string;
    EDIT: string;
    LOGIN: string;
    ACCOUNT: string;
    UNSAVED_CHANGES: string;
    HOME: string;
    CASES: string;
    CONTACT: string;
    ABOUT: string;
    WRONG_CREDENTIALS: string;
    NAME: string;
    NAME_NOT_UNIQUE: string;
    PASSWORD: string;
    CHANGE_PASSWORD: string;
    NEW_PASSWORD: string;
    REPEAT_PASSWORD: string;
    PASSWORD_DOES_NOT_МАТCH: string;
    CREATE_ACCOUNT: string;
    ENTER: string;
    TITLE: string;
    DESCRIPTION: string;
    IMAGE: string;
    PLEASE_ENTER: string;
    REQUIRES_LOGIN: string;
    PAGE_NOT_FOUND: string;
    CONFIRM_DELETE: string;
    PHONE: string;
    EMAIL: string;
    ADDRESS: string;
    SESSION_EXPIRED: string;
    LOG_OUT: string;
    CASE: string;
    ACTION_REQUIRES_LOGIN: string;
    ERROR_OCCURED: string;
    SAVED_CHANGES: string;
    YES: string;
    NO: string;
    CLOSE: string;
    OK: string;
    VALID: string;
    LATITUDE: string;
    LONGITUDE: string;
    INVALID_VALUE: string;
    VALUE_BETWEEN: string;
    SITE_TITLE: string;
    PERSON: string;
    WORK_HOURS: string;
    RANGE: string;
    DAYS: string;
    HOURS: string;
    FOLLOW_US_IN: string;
    BEFORE: string;
    AFTER: string;
    DETAILS: string;
    CASE_INFO: string;
    ITEM_NOT_FOUND: string;
    MORE_INFORMATION: string;
    PODIATRY: string;
    CONTACT_US: string;
    WHO_ARE_WE: string;
    LOGO: string;
    TEXT_EDITOR_DESCRIPTION: string;
    JOB_TITLE: string;
    PODIATRY_CENTER: string;
    MORE_OPTIONS: string;
}
