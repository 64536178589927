import React, { useContext } from "react";
import IconButton from "../../Helpers/Button/IconButton"
import { AddIcon } from "../../Helpers/Button/icons"
import MessageContext from "../../../utils/messages/message-context";
import { extend } from "../../../utils/helpers";
import WorkHoursRange from "./WorkHoursRange"
import './work-hours.css';

export default function WorkHours({ value, onChange }: { value: any[], onChange: Function} ) {
    const messages = useContext(MessageContext);
    function onRangeChange(updatedRange: any, rangeIndex: number) {
        const newRanges = value.map((range: any, index: number) => {
            if (index === rangeIndex) {
                return extend(range, updatedRange);
            }

            return range;
        });

        onChange({ value: newRanges });
    }

    function onRangeAdd() {
        let startDay = 0;
        if (value.length) {
            const last = value[value.length - 1];
            startDay = ((last.endDay || last.startDay) + 1) || 0;
        }
        onChange({ value: value.concat({
            startDay,
            ranges: [['00:00', '00:00']]
        }) });
    }

    function onRangeRemove(rangeIndex: number) {
        onChange({
            value: value.filter((range: any, index: number) => index !== rangeIndex)
        });
    }

    return (
        <div className="work-hours">
            <div className="command-row">
                <IconButton IconElement={AddIcon} onClick={onRangeAdd}>
                    {messages.ADD} { messages.DAYS }
                </IconButton>
            </div>
            {
                value && value.map((range: any, index: number) => (
                    <React.Fragment key={index}>
                        <WorkHoursRange range={range} onChange={(range: any) => onRangeChange(range, index)}
                            onRemove={() => onRangeRemove(index)} />
                        <hr />
                    </React.Fragment>
                ))
            }
        </div>
    )
}
