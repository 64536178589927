

export default function DropDown({ data, value, onChange, textField, valueField}:
    { data: any[], value: any, onChange: Function, textField: string, valueField: string }) {
    function onInputChange(e: any) {
        const { value, selectedIndex } = e.target;
        onChange({ value, item: selectedIndex > 0 ? data[selectedIndex - 1] : null });
    }

    return (
        <select value={value} onChange={onInputChange} className="input">
            <option></option>
            ${
                data.map((item: any, index: number) => (
                    <option key={item[valueField] | item} value={item[valueField] | item}>
                        {item[textField] || item}
                    </option>
                ))
            }
        </select>
    )
}
