import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import MessageContext from '../../../utils/messages/message-context';
import IconButton from './IconButton'
import { BackIcon } from './icons';

export default function BackButton({ onClick }: { onClick?: Function}) {
    const messages = useContext(MessageContext);
    const navigate = useNavigate();
    const backClick = onClick || (() => navigate(-1));

    return (
        <div className="actions">
            <IconButton IconElement={BackIcon} onClick={backClick}>
                { messages.BACK }
            </IconButton>
        </div>
    );
}
