
import { useAppSelector } from '../../../data/store/store';
import PersonView from './PersonView';
import { useContext } from 'react';
import ServiceContext from '../../../data/ServiceContext';
import ListView from '../../Helpers/ListView/ListView';
import './about.css';
import MessageContext from '../../../utils/messages/message-context';

const alternatePeople = (_item: any, index: number) => index % 2 !== 0 ? 'person-right' : '';

export default function AboutUs() {
    const messages = useContext(MessageContext);
    const { peopleService } = useContext(ServiceContext);
    const people = useAppSelector(state => state.people);

    return (
        <>
            <h1 className="page-title">{messages.WHO_ARE_WE}</h1>
            <ListView service={peopleService} data={people} itemClass={alternatePeople}
                listClass="people" wrapperClass="about-content" ItemElement={PersonView} />
        </>
    );
}
