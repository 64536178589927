import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import MessageContext from "../../utils/messages/message-context";
import NotificationType from "../Helpers/Notification/NotificationType";
import { useAppSelector } from "../../data/store/store";
import ServiceContext from "../../data/ServiceContext";
import { UserRole } from "../Pages/User/user-role";

const useSaveErrorHandler = () => {
    const navigate = useNavigate();
    const messages = useContext(MessageContext);
    const { popupService, loginService, notificationService } = useContext(ServiceContext);
    const role = useAppSelector(state => state.role);

    return (result: any) => {
        if (result?.response?.status === 401) {
            popupService.show(role === UserRole.Admin ? messages.SESSION_EXPIRED : messages.ACTION_REQUIRES_LOGIN, {
                actions: [{ text: messages.OK }]
            }).then(() => {
                loginService.resetRole();
                navigate('/login');
            });
        } else {
            notificationService.show(NotificationType.Error, messages.ERROR_OCCURED);
        }
    }
};

export default useSaveErrorHandler;