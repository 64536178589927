import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const popupSlice = createSlice({
    name: 'popup',
    reducers: {
        togglePopup: (_state, action: PayloadAction<boolean>) => {
            return action.payload;
        }
    },
    initialState: false
});

export const { togglePopup } = popupSlice.actions;

export default popupSlice;