import { useNavigate } from "react-router-dom";
import { useContext, useState } from 'react';
import Form, { FormProps } from './Form';
import ServiceContext from '../../../data/ServiceContext';
import NotificationType from '../Notification/NotificationType';
import MessageContext from '../../../utils/messages/message-context';
import useSaveErrorHandler from '../../Hooks/useSaveErrorHandler';

export interface RemoteFormProps extends FormProps {
    onSave: (data: any) => Promise<any>;
}

export default function RemoteForm({ model, inputs, validator, onSave, onChange, onCancel, title }: RemoteFormProps) {
    const messages = useContext(MessageContext);
    const services = useContext(ServiceContext);
    const navigate = useNavigate();
    const erroHandler = useSaveErrorHandler();
    const [errors, setErrors] = useState<string[]>([]);

    function saveForm(data: any) {
        onSave(data).then((result: any) => {
            if (result?.data?.errors) {
                setErrors(result.data.errors);
            } else {
                services.notificationService.show(NotificationType.Success, messages.SAVED_CHANGES);
                navigate(-1);
            }

            return result;
        }).catch(erroHandler);
    }

    return (
        <Form model={model} inputs={inputs} validator={validator} modelErrors={errors} title={ title }
                    onChange={onChange} onSave={saveForm} onCancel={onCancel} saveText={messages.SAVE}
                    cancelText={messages.CANCEL} />
    );
}
