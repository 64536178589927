import { ReactElement } from "react";
import { RootStore } from "../../../data/store/store";
import { togglePopup } from "./popup.slice";
import PopupAction from "./popup-aciton";
import { CancelIcon, SaveIcon } from "../Button/icons";
import { PopupOptions } from "./popup-options";

export default class PopupService {
    public content: ReactElement | undefined;
    public options: PopupOptions | undefined;
    private resolvePromise: any;
    private store: RootStore;

    constructor(store: RootStore) {
        this.store = store;
    }

    public onClose = (action: PopupAction): void => {
        if (this.resolvePromise) {
            this.resolvePromise(action);
        }
        this.hide();
    }

    public show(content: any, options?: PopupOptions): Promise<any> {
        this.content = content;
        this.options = options;
        this.store.dispatch(togglePopup(true));

        return new Promise((resolve) => {
            this.resolvePromise = resolve;
        });
    }

    public showConfirm(content: any, confirmMessage: string, declineMessage: string): Promise<any> {
        this.content = content;
        this.options = {
            actions: [{ icon: SaveIcon, text: confirmMessage }, { icon: CancelIcon, text: declineMessage }],
            breakContent: true
        };
        this.store.dispatch(togglePopup(true));

        return new Promise((resolve) => {
            this.resolvePromise = (action: PopupAction) => {
                if (action.text === confirmMessage) {
                    resolve(action);
                }
            };
        });
    }

    public hide() {
        this.content = undefined;
        this.options = undefined;
        this.resolvePromise = undefined;
        this.store.dispatch(togglePopup(false));
    }
}
