import { extend } from "../../../utils/helpers";
import DayPicker from './DayPicker';

export default function DayRange({ range, onChange }: { range: { startDay: number, endDay: number}, onChange: Function }) {
    function onStartChange(value: number) {
        onChange(extend(range, { startDay: value }));
    }

    function onEndChange(value: number) {
        onChange(extend(range, { endDay: value }));
    }

    return (
        <>
            <DayPicker value={range.startDay} onChange={onStartChange}/>
            <span>&nbsp;-&nbsp;</span>
            <DayPicker value={range.endDay} onChange={onEndChange}/>
        </>
    );
}