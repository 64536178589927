import { configureStore  } from '@reduxjs/toolkit';
import notificationSlice from '../../Components/Helpers/Notification/notification-slice';
import popupSlice from '../../Components/Helpers/Popup/popup.slice';
import roleSlice, { setRole } from '../../Components/Pages/User/role-slice';
import caseSlice, { setItems as setCases } from '../../Components/Pages/Cases/data/case-slice';
import caseInfoSlice, { setItems as setCaseInfo } from '../../Components/Pages/CaseInfo/data/case-info.slice';
import peopleSlice, { setItems as setPeople } from '../../Components/Pages/About/data/people-slice';
import pageInfoSlice, { setPageInfo } from '../page-info/page-info.slice';
import { UserRole } from '../../Components/Pages/User/user-role';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import loadingSlice, { setLoading } from '../../Components/Helpers/Loading/loading.slice';

export const createStore = () => configureStore({
    reducer: {
        cases: caseSlice.reducer,
        caseInfo: caseInfoSlice.reducer,
        role: roleSlice.reducer,
        loading: loadingSlice.reducer,
        notifications: notificationSlice.reducer,
        popup: popupSlice.reducer,
        pageInfo: pageInfoSlice.reducer,
        people: peopleSlice.reducer
    }
});

const store = createStore();
export type RootState = ReturnType<typeof store.getState>;
export type RootStore = typeof store;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { setCases, setCaseInfo, setPageInfo, setRole, setPeople, setLoading };

export function initStore(store: RootStore, data: any) {
    if (data.cases) {
        store.dispatch(setCases(data.cases));
    }

    if (data.caseInfo) {
        store.dispatch(setCaseInfo(data.caseInfo));
    }

    if (data.pageInfo) {
        store.dispatch(setPageInfo(data.pageInfo));
    }

    if (data.people) {
        store.dispatch(setPeople(data.people));
    }

    store.dispatch(setRole(data.role === 'admin' ? UserRole.Admin : UserRole.Anonymous));
}

export default store;