import { ElementType, ReactNode } from "react";

import './button.css';

export interface IconButtonProps {
    IconElement?: string | ElementType;
    onClick?: Function;
    children?: ReactNode;
    props?: any;
    disabled?: boolean;
}


export default function IconButton({IconElement, onClick, children, disabled, props}: IconButtonProps) {
    return (
        <button type="button" onClick={onClick} className={`button ${disabled ? 'disabled' : ''}`} {...props}>
            { IconElement && <IconElement className="icon"/> }
            {children}
        </button>
    )
}
