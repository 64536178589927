import { useContext } from 'react';
import IconButton from '../../Helpers/Button/IconButton'
import { AddIcon, DeleteIcon } from '../../Helpers/Button/icons';
import MessageContext from '../../../utils/messages/message-context';
import TimeRange from './TimeRange';

export default function TimeRangeList({ ranges, onChange }: { ranges: string[][], onChange: Function }) {
    const messages = useContext(MessageContext);
    function onRangeChange(change: string[], index: number) {
        const newRanges = ranges.slice(0);
        newRanges[index] = change;

        onChange(newRanges);
    }

    function onRangeRemove(index: number) {
        const newRanges = ranges.slice(0);
        newRanges.splice(index, 1);

        onChange(newRanges);
    }

    function onRangeAdd() {
        const newRanges = ranges.slice(0);
        const lastRange = ranges[ranges.length - 1];
        const newRange = lastRange ? [lastRange[1], lastRange[1]] : ["00:00", "00:00"];

        newRanges.push(newRange);
        onChange(newRanges);
    }

    return (
        <>
            <div className="command-row">
                <IconButton IconElement={AddIcon} onClick={onRangeAdd}>
                    {messages.ADD} { messages.HOURS}
                </IconButton>
            </div>
         {
            ranges && ranges.map((range: string[], index: number) => (
                <div key={index} className="time-range">
                    <TimeRange range={range} onChange={(e: string[]) => onRangeChange(e, index)}/>
                    <IconButton IconElement={DeleteIcon} onClick={() => onRangeRemove(index)}>
                    </IconButton>
                </div>
            ))
         }
        </>
    )
}
