import ListEditForm from '../../../Helpers/ListView/ListEditForm';
import ServiceContext from '../../../../data/ServiceContext';
import { useContext } from 'react';
import MessageContext from '../../../../utils/messages/message-context';
import formProps from './form-props';
import useFindById from '../../../Hooks/useFindById';
import ItemNotFound from '../../../Helpers/Page/ItemNotFound';

export default function AddForm() {
    const messages = useContext(MessageContext);
    const { caseService } = useContext(ServiceContext);
    const item = useFindById(state => state.cases);

    if (!item) {
        return (<ItemNotFound />);
    }

    return (
        <ListEditForm data={item} onSave={ caseService.update }
            title={`${ messages.EDIT} ${messages.CASE}`} {...formProps} />
    );
}
