import { ElementType, useContext, useMemo, useState } from 'react';
import { Rules, Validator } from '../../../data/model/validator';
import { extend, readBase64 } from '../../../utils/helpers';
import RemoteForm from '../Form/RemoteForm';
import BackButton from '../Button/BackButton';
import useBackConfirm from '../../Hooks/useBackConfirm';
import MessageContext from '../../../utils/messages/message-context';
import { APP_MESSAGES } from '../../../utils/messages/messages';
import { InputOptions } from '../Form/Form';

export interface BaseFormProps {
    data: any;
    onSave: (data: any) => Promise<any>;
    title: string;
    rules: (messages: APP_MESSAGES) => Rules;
    createInputs: (messages: APP_MESSAGES) => { [key: string]: InputOptions };
    imageFields?: string[];
    ItemElement: ElementType;
    previewItemClass?: string;
    listClass?: string;
}

export default function ListEditForm({ data, onSave, title, rules, createInputs, imageFields, ItemElement,
        previewItemClass, listClass }: BaseFormProps) {
    const messages = useContext(MessageContext);
    const [item, setItem] = useState<any>(data);
    const [confirm, enableConfirm] = useBackConfirm(messages.UNSAVED_CHANGES);
    const validator = useMemo(() => new Validator(rules(messages)), [rules, messages]);
    const inputs = useMemo(() => createInputs(messages), [createInputs, messages]);

    function onChange(name: string, value: any) {
        enableConfirm();

        if (imageFields && imageFields.some((field: string) => field === name)) {
            if (!value) {
                return;
            }
            readBase64(value, (base64: string) => setItem(extend(item, { [name]: base64 })));
        } else {
            setItem(extend(item, { [name]: [value]}));
        }
    }

    return (
        <>
            <BackButton onClick={confirm}/>

            <ul className={ listClass || 'listview' }>
                <li className="list-item list-form-item">
                    <RemoteForm model={item} inputs={inputs} validator={validator} title={title}
                        onChange={onChange} onSave={onSave} saveText={messages.SAVE}
                        cancelText={messages.CANCEL} onCancel={confirm} />
                </li>
                <li className={ previewItemClass || "list-item" }>
                    <ItemElement {...item} />
                </li>
            </ul>
        </>
    );
}
