import React, { useContext } from 'react'
import IconLink from '../../Helpers/Button/IconLink';
import { FaceBookIcon } from '../../Helpers/Button/icons';
import MessageContext from '../../../utils/messages/message-context';
import { useAppSelector } from '../../../data/store/store';
import WorkHoursList from '../WorkHours/WorkHoursList';
import { COPYRIGHT, FACEBOOK_LINK } from '../../../constants';

export default function Footer() {
    const messages = useContext(MessageContext);
    const info = useAppSelector(state => state.pageInfo);

    return (
        <footer>
            <div className="footer-column footer-contacts">
                <div> { messages.CONTACT}:</div>
                <div>{ messages.ADDRESS }: { info.contacts.address }</div>
                <div>{ messages.PHONE }: { info.contacts.phone }</div>
            </div>
            <div className='footer-column footer-middle'>
                <div>
                    { messages.FOLLOW_US_IN } :

                    <IconLink IconElement={FaceBookIcon} href={FACEBOOK_LINK}
                        props={{ 'aria-label': `${ messages.FOLLOW_US_IN } Facebook`, target: '_blank' }} />
                </div>
                <div>
                    { COPYRIGHT }
                </div>
            </div>
            <div className="footer-column footer-work-hours">
                <WorkHoursList/>
            </div>
        </footer>
    )
}
