import { Params, useNavigate, useParams, useLocation } from "react-router-dom";
import IconButton from "../Button/IconButton";
import { RootState, useAppSelector } from "../../../data/store/store";
import { UserRole } from "../../Pages/User/user-role";
import BackButton from '../Button/BackButton';
import { LoginIcon } from "../Button/icons";
import { useContext } from "react";
import MessageContext from "../../../utils/messages/message-context";
import { Helmet } from "react-helmet";
import { CANNONICAL_URL } from "../../../constants";

export default function Page({ component, requireLogin, title }:
    { component: JSX.Element, requireLogin?: boolean, title: string | ((state: RootState, params: Params<string>) => string) }) {
    const messages = useContext(MessageContext);
    const state = useAppSelector(state => state);
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const pageTitle = typeof title === 'function' ? title(state, params) : title;
    const siteTitle = `${pageTitle} - ${messages.SITE_TITLE}`;

    if (requireLogin && state.role !== UserRole.Admin) {
        return (
            <div>
                <h3>{messages.REQUIRES_LOGIN}</h3>
                <div>
                    <BackButton />
                    <IconButton IconElement={LoginIcon} onClick={() => navigate('/login')}>
                        {messages.LOGIN}
                    </IconButton>
                </div>
            </div>
        );
    }

    return (
        <>
            <Helmet>
                <title>{siteTitle}</title>
                <link rel="canonical" href={`${CANNONICAL_URL}${location.pathname}`}/>
            </Helmet>
            {component}
        </>
    );
}
