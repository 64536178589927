import CaseInfoView from "../CaseInfoView";
import { APP_MESSAGES } from "../../../../utils/messages/messages";
import Upload from "../../../Helpers/Form/Upload";
import { caseInfoRules } from "../../../../data/model/rules";
import { IMAGE_FIELDS } from "../constants";

const createInputs = (messages: APP_MESSAGES) => ({
    title: {
      type: 'input',
      label: messages.TITLE
    },
    description: {
      type: 'textarea',
      label: messages.DESCRIPTION,
      props: { rows: 8 }
    },
    image: {
      type: Upload,
      props: { text: `${messages.IMAGE}` }
    }
});

const formProps = {
    createInputs,
    rules: caseInfoRules,
    imageFields: IMAGE_FIELDS,
    ItemElement: CaseInfoView
};

export default formProps;