
import { RootStore } from '../../../../data/store/store';
import { removeItem, createItem, updateItem, setItems } from './people-slice';
import Person from './Person';
import CrudService from '../../../../data/utils/crud.service';
import { serviceUrl } from '../../../../data/utils/service-helpers';

const URL = serviceUrl('people');
const actions = { removeItem, createItem, updateItem, setItems };
const parsers = { id: (value: any) => Number(value) };

export default class PeopleService extends CrudService<Person> {

    constructor(store: RootStore) {
        super(store, URL, actions, parsers);
    }

}