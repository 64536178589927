import { MouseEventHandler } from 'react';

export default function OverflowButton({ onClick, title }:
  { onClick: MouseEventHandler<HTMLButtonElement>, title: string }) {

    return (
      <button type="button" className="menu-overflow-button" onClick={onClick} title={title}>
        <svg  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 6H20M4 12H20M4 18H20" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </button>
    );
  }