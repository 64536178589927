import { useMemo, useState } from "react";
import { classNames } from "../../../utils/helpers";
import './tabstrip.css';

const TabStrip = ({ items, children }: any) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const headerItems = useMemo(() => items.map((item: any, index: number) => (
            <span className={classNames({ 'header-item': true, selected: selectedIndex === index})}
                onClick={() => setSelectedIndex(index)} key={index}>{item.text || item}</span>
        )),
    [items, selectedIndex]);

    let content = children && children[selectedIndex];
    if (!content && items.length === 1) {
      content = children;
    }

    return (
        <div className="tabstrip">
            <div className="tabstrip-header">
                {headerItems}
            </div>
            <div className="tabstrip-content">
                {content}
            </div>
        </div>
    );
}

export default TabStrip;