import { Outlet, useNavigate } from 'react-router-dom';
import ServiceContext from '../../../data/ServiceContext';
import { useCallback, useContext } from 'react';
import NotificationContainer from '../../Helpers/Notification/NotificationContainer';
import Popup from '../../Helpers/Popup/Popup';
import AdminContent from '../User/AdminContent';
import MessageContext from '../../../utils/messages/message-context';
import Loading from '../../Helpers/Loading/Loading';
import { useAppSelector } from '../../../data/store/store';
import './layout.css';
import Footer from './Footer';
import Navigation from './Navigation';



export default function Layout(props: any) {
    const messages = useContext(MessageContext);
    const popup = useAppSelector(state => state.popup);
    const { loginService, popupService } = useContext(ServiceContext);
    const navigate = useNavigate();
    const logout = useCallback(() => {
        loginService.logoutUser().then(() => {
            navigate('/', { replace: true });
        });
    }, [loginService]);

    return (
        <div className="content">
            <header className="header">
                <AdminContent>
                    <div className='actions-right'>
                        <button className="button" onClick={logout}>{messages.LOG_OUT}</button>
                    </div>
                </AdminContent>
                <Navigation />
            </header>
            <main className="content-wrapper" >
                <Outlet />
            </main>
            <Footer />
            <Loading />
            <NotificationContainer />
            { popup &&
                <Popup content={popupService.content} options={popupService.options} onClose={popupService.onClose}/>
            }
        </div>
    );
}
