import { useContext, useMemo } from 'react';
import { Validator } from '../../../data/model/validator';
import ServiceContext from '../../../data/ServiceContext';
import RemoteForm from '../../Helpers/Form/RemoteForm';
import BackButton from '../../Helpers/Button/BackButton';
import useBackConfirm from '../../Hooks/useBackConfirm';
import { homeRules } from '../../../data/model/rules';
import MessageContext from '../../../utils/messages/message-context';
import { useAppSelector } from '../../../data/store/store';


export default function HomeForm() {
    const messages = useContext(MessageContext);
    const info = useAppSelector(state=> state.pageInfo);
    const { infoService } = useContext(ServiceContext);
    const [confirm, enableConfirm] = useBackConfirm(messages.UNSAVED_CHANGES);
    const validator = useMemo(() => new Validator(homeRules(messages)), [messages]);
    const inputs = useMemo(() => ({
        title: {
          type: 'input',
          label: messages.TITLE
        },
        description: {
          type: 'textarea',
          label: messages.DESCRIPTION,
          props: { rows: 12 },
          valueDescription: messages.TEXT_EDITOR_DESCRIPTION
        }
    }), [messages]);

    return (
        <div className="home-form">
          <BackButton onClick={confirm} />
          <RemoteForm model={info.home} inputs={inputs} validator={validator}
            title={`${messages.EDIT} ${ messages.HOME }`}
            onSave={infoService.updateHome} saveText={messages.SAVE} onChange={enableConfirm}
            cancelText={messages.CANCEL} onCancel={confirm} />
        </div>
    );
}
