import CaseInfo from './data/CaseInfo';
import { imageUrl } from '../../../data/utils/service-helpers';
import BackButton from '../../Helpers/Button/BackButton';
import useFindById from '../../Hooks/useFindById';
import ItemNotFound from '../../Helpers/Page/ItemNotFound';
import { CASE_INFO_DIRECTORY } from './constants';

export default function CaseInfoDetails() {
    const item = useFindById<CaseInfo>(state => state.caseInfo);

    if (!item) {
        return (<ItemNotFound />);
    }

    return (
        <>
            <BackButton />
            <div className="case-details">
                <h1 className="page-title-center">{ item.title }</h1>
                <img className="case-info-image" src={ imageUrl(item.imageName, CASE_INFO_DIRECTORY) }
                        alt={item.title} />
                <p>{ item.description }</p>
            </div>
        </>
    );

}
