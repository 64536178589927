
import { EditIcon } from "../../Helpers/Button/icons";
import { useAppSelector } from "../../../data/store/store";
import AdminContent from "../User/AdminContent";
import EditButton from "../../Helpers/Button/EditButton";
import ParagraphList from './ParagraphList';
import './home.css';
import { useContext } from "react";
import MessageContext from "../../../utils/messages/message-context";

export default function Home() {
    const messages = useContext(MessageContext);
    const info = useAppSelector(state => state.pageInfo);
    const { title, description } = info.home;

    return (
        <>
            <AdminContent>
                <EditButton path="/home/edit" message={messages.EDIT} icon={EditIcon}/>
            </AdminContent>
            <div className="home-content">
                <h1 className="page-title-center">{title}</h1>
                <ParagraphList text={description} />
            </div>
        </>
    );
}
