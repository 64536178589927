import { ElementType } from "react";

export interface FormFieldProps {
    InputElement: string | ElementType;
    label?: string | undefined;
    name: string;
    props?: any;
    error: string;
    value: any;
    onChange: ({name, value}: { name: string, value: any}) => void;
    parse?: Function;
    valueDescription?: string;
}

export default function FormField({ InputElement, label, props, error, value, onChange, name, parse, valueDescription }: FormFieldProps) {
    function onInputChange(e: any) {
        const value = e.target ? e.target.value : e.value;
        onChange({
            value: !parse? value : parse(value),
            name
        });
    }

    return (
        <div className="form-field">
            { label &&
                <label className="form-label" htmlFor={name}>
                    {label}
                </label>
            }
            <InputElement id={name} className="input" value={value} name={name} onChange={onInputChange} {...props} />
            { valueDescription && <div className="value-description">{ valueDescription }</div>}
            {
                error && <span className="error">{error}</span>
            }
        </div>
    )
}
