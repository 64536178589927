
import { useContext } from 'react';
import ListEditForm from '../../../Helpers/ListView/ListEditForm';
import useFindById from '../../../Hooks/useFindById';
import MessageContext from '../../../../utils/messages/message-context';
import ItemNotFound from '../../../Helpers/Page/ItemNotFound';
import ServiceContext from '../../../../data/ServiceContext';
import formProps from './form-props';

export default function EditForm() {
    const messages = useContext(MessageContext);
    const { caseInfoService } = useContext(ServiceContext);
    const item = useFindById(state => state.caseInfo);

    if (!item) {
        return (<ItemNotFound />);
    }

    return (
        <ListEditForm data={item} onSave={caseInfoService.update}
            title={`${ messages.EDIT} ${messages.CASE_INFO}`} {...formProps} />
    )
}
