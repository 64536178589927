import Case from './data/Case';
import { imageUrl } from '../../../data/utils/service-helpers';
import BackButton from '../../Helpers/Button/BackButton';
import { useContext } from 'react';
import MessageContext from '../../../utils/messages/message-context';
import useFindById from '../../Hooks/useFindById';
import ItemNotFound from '../../Helpers/Page/ItemNotFound';
import { CASE_DIRECTORY } from './constants';

export default function CaseDetails() {
    const messages = useContext(MessageContext);
    const item = useFindById<Case>(state => state.cases);

    if (item) {
        return (
            <>
                <BackButton />
                <div className="case-details">
                    <h1 className="page-title-center">{ item.title }</h1>
                    <div className="case-detail-images">
                        <img className="case-detail-image" src={ imageUrl(item.imageBeforeName, CASE_DIRECTORY) }
                            alt={`${ messages.BEFORE } ${ item.title }`} />
                        { item.imageAfterName &&
                            <img className="case-detail-image" src={ imageUrl(item.imageAfterName, CASE_DIRECTORY) }
                                alt={`${ messages.AFTER } ${ item.title }`} />
                        }
                    </div>
                    <p>{ item.description }</p>
                </div>
            </>
        );
    }

    return (<ItemNotFound />);
}
