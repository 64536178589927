import { ElementType } from 'react'
import { useNavigate } from 'react-router-dom'
import IconButton from './IconButton'
import { EditIcon } from './icons'

export default function EditButton({ path, icon, message }:
    { path: string, icon?: string | ElementType, message: string }) {
    const navigate = useNavigate();

    return (
        <div className="actions-right">
            <IconButton IconElement={icon || EditIcon} onClick={() => navigate(path)}>
                {message}
            </IconButton>
        </div>
    );
}
