import { ListItem } from "../Components/Helpers/ListView/ListItem";
import { RootState } from "../data/store/store";
import { Params } from "react-router-dom";

export default function pageTitle<T extends ListItem>(selector: (state: RootState) => T[],
    defaultTitle: string): (state: RootState, params: Params<string>) => string {

    return (state: RootState, params: Params<string>) => {
        const { id } = params;
        const items = selector(state);
        const idValue = Number(id);
        const item = items.find((item: T) => item.id === idValue);
        return item ? (item as any).title : defaultTitle;
    };
}