import { ReactElement } from "react";
import { classNames } from "../../../utils/helpers";

export interface AnimationContainerProps {
    onEnd: Function;
    remove?: boolean;
    children: ReactElement;
    props?: any;
    show?: boolean;
    Tag?: string;
}

export default function AnimationContainer({ onEnd, remove, children, props, show = false, Tag = 'div' }: AnimationContainerProps) {
    function onAnimationEnd(e: any) {
        if (e.animationName === 'remove-animation') {
            onEnd();
        }
    }

    const animationClasses = classNames({
        'remove-animation': remove,
        'show-animation': show && !remove
    });

    return (
        <Tag className={animationClasses}
             onAnimationEnd={onAnimationEnd} {...props}>
            { children}
        </Tag>
    );
}