import SERVICE_URL from "./service-url";

export const serviceUrl = (path: string) => SERVICE_URL + path;

export const imageUrl = (path?: string, directory?: string) =>
    path ? `${SERVICE_URL}images/${ directory ? directory + '/' : ''}${path}` : '';

export function createFormData(data: any) {
    const formData = new FormData();

    Object.keys(data).forEach((field: string) => {
        if (data.hasOwnProperty(field)) {
            formData.append(field, data[field]);
        }
    });

    return formData;
}

export const FORM_DATA_HEADERS = { "Content-Type": "multipart/form-data" };