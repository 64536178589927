import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserRole } from "./user-role";

const roleSlice = createSlice({
    name: 'role',
    reducers: {
        setRole: (_state, action: PayloadAction<UserRole>) => {
            return action.payload;
        }
    },
    initialState: UserRole.Anonymous
});

export const { setRole } = roleSlice.actions;

export default roleSlice;