import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import store, { initStore } from './data/store/store';
import './index.css';

const initial = (window as any).__initialState;

initStore(store, initial);

const container = document.getElementById('root') as HTMLElement;
ReactDOM.hydrateRoot(container,
  <React.StrictMode>
    <BrowserRouter>
      <App store={store}/>
    </BrowserRouter>
  </React.StrictMode>);


