
import { RootStore } from '../../../../data/store/store';
import { removeItem, createItem, updateItem, setItems } from './case-slice';
import Case from './Case';
import { serviceUrl} from '../../../../data/utils/service-helpers';
import CrudService from '../../../../data/utils/crud.service';

const URL = serviceUrl('case-api');
const actions = { setItems, updateItem, removeItem, createItem };
const parsers = { id: (value: any) => Number(value) };

export default class CaseService extends CrudService<Case> {

    constructor(store: RootStore) {
        super(store, URL, actions, parsers);
    }

}