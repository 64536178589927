import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const loadingSlice = createSlice({
    name: 'loading',
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            return action.payload ? state + 1 :  Math.max(state - 1, 0);
        }
    },
    initialState: 0
});

export const { setLoading } = loadingSlice.actions;

export default loadingSlice;